var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BN, Program, web3 } from '@project-serum/anchor';
import { confirmTransaction, createAssociatedTokenAccountInstrs, findAssociatedTokenAccount, sendTransaction } from '../../anchor';
import { getVestingConfig } from '../configs';
import { createInitVestingInstr, createVestingClaimInstr, createVestingStakeInstr, createVestingUnvestInstr, createVestingVestInstr } from '../instructions';
import { findVestingAddress } from '../utils';
export class VestingProgram {
    constructor(provider, overrideConfig) {
        this.provider = provider;
        this.simulate = false;
        this.vestingConfig = overrideConfig !== null && overrideConfig !== void 0 ? overrideConfig : getVestingConfig();
        this.program = new Program(this.vestingConfig.programIdl, this.programId, provider);
    }
    /**
     * Vesting program ID
     *
     * @readonly
     * @memberof VestingProgram
     */
    get programId() {
        return new web3.PublicKey(this.vestingConfig.programId);
    }
    /**
     * Parrot Vesting config
     *
     * @readonly
     * @memberof VestingProgram
     */
    get parrotVestingConfig() {
        return {
            vestConfig: new web3.PublicKey(this.vestingConfig.vestConfig),
            claimableMint: new web3.PublicKey(this.vestingConfig.claimableMint),
            vestMint: new web3.PublicKey(this.vestingConfig.vestMint)
        };
    }
    confirmTransaction(transaction, txId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield confirmTransaction(this.provider, txId, transaction, 'confirmed');
        });
    }
    /**
     *
     * @param address Vesting account address
     * @returns
     */
    getVestingAccount(ownerPk) {
        return __awaiter(this, void 0, void 0, function* () {
            const publicKey = yield findVestingAddress(this.programId, this.parrotVestingConfig.vestConfig, ownerPk);
            const account = yield this.program.account.vesting.fetch(publicKey);
            if (!account) {
                throw new Error(`Vesting account not found for ${publicKey}`);
            }
            return Object.assign(Object.assign({}, account), { publicKey });
        });
    }
    getVestingAccounts() {
        return __awaiter(this, void 0, void 0, function* () {
            const accounts = yield this.program.account.vesting.all();
            return accounts.map(i => i.account);
        });
    }
    /**
     *
     * @returns
     */
    getVestConfigAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            const publicKey = this.parrotVestingConfig.vestConfig;
            const account = yield this.program.account.vestConfig.fetch(publicKey);
            return Object.assign(Object.assign({}, account), { publicKey });
        });
    }
    /**
     * Stake user PRT to vesting program and mint yPRT to the user (1:1)
     * @returns
     */
    stake(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            const { vestMint, vestConfig, claimableMint } = this.parrotVestingConfig;
            let userVestTokenAccount = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, vestMint);
            if (!userVestTokenAccount) {
                const { instructions: createTokenAccountInstructions, signers: createTokenAccountSigners, extra: { tokenAccount } } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: vestMint,
                    owner: this.provider.wallet.publicKey
                });
                userVestTokenAccount = tokenAccount;
                instructions.push(...createTokenAccountInstructions);
                signers.push(...createTokenAccountSigners);
            }
            const stakeInstrs = yield createVestingStakeInstr(this.program, vestConfig, claimableMint, vestMint, userVestTokenAccount, this.provider.wallet.publicKey, new BN(amount.toString()));
            instructions.push(stakeInstrs);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers, true, this.simulate);
            console.log('Vesting Stake txId', txId);
            return { txId, transaction };
        });
    }
    /**
     * @returns
     */
    vest(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            const { vestMint, vestConfig } = this.parrotVestingConfig;
            const userVestingAccountAddress = yield findVestingAddress(this.programId, vestConfig, this.provider.wallet.publicKey);
            const userVestingAccount = yield this.provider.connection.getAccountInfo(userVestingAccountAddress);
            if (!userVestingAccount) {
                const initVestingInstr = yield createInitVestingInstr(this.program, vestConfig, vestMint, userVestingAccountAddress, this.provider.wallet.publicKey);
                instructions.push(initVestingInstr);
            }
            const vestInstrs = yield createVestingVestInstr(this.program, vestConfig, vestMint, userVestingAccountAddress, this.provider.wallet.publicKey, new BN(amount.toString()));
            instructions.push(...vestInstrs);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers, true, this.simulate);
            console.log('Vesting Vest txId', txId);
            return { txId, transaction };
        });
    }
    /**
     * @returns
     */
    unvest(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            const { vestMint, vestConfig } = this.parrotVestingConfig;
            // Check if vesting account exist
            const userVestingAccountAddress = yield findVestingAddress(this.programId, vestConfig, this.provider.wallet.publicKey);
            const userVestingAccount = yield this.provider.connection.getAccountInfo(userVestingAccountAddress);
            if (!userVestingAccount) {
                throw new Error('vesting account not found');
            }
            const vestInstrs = yield createVestingUnvestInstr(this.program, vestConfig, vestMint, this.provider.wallet.publicKey, new BN(amount.toString()));
            instructions.push(...vestInstrs);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers, true, this.simulate);
            console.log('Vesting Unvest txId', txId);
            return { txId, transaction };
        });
    }
    /**
     * @returns
     */
    claim() {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            const { vestMint, claimableMint, vestConfig } = this.parrotVestingConfig;
            const userClaimableAccount = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, claimableMint);
            if (!userClaimableAccount) {
                const { instructions: createTokenAccountInstructions } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: claimableMint,
                    owner: this.provider.wallet.publicKey
                });
                instructions.push(...createTokenAccountInstructions);
            }
            const vestInstrs = yield createVestingClaimInstr(this.program, vestConfig, vestMint, claimableMint, this.provider.wallet.publicKey);
            instructions.push(...vestInstrs);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers, true, this.simulate);
            console.log('Vesting Claim txId', txId);
            return { txId, transaction };
        });
    }
}
