import fs from 'fs';
import path from 'path';
export const getFaucetConfigPath = () => {
    var _a;
    const CHANNEL = (_a = process.env.CHANNEL) !== null && _a !== void 0 ? _a : process.env.NEXT_PUBLIC_CHANNEL;
    if (!CHANNEL) {
        throw new Error(`Environment variable "CHANNEL" is required.`);
    }
    const configFilePath = path.resolve(__dirname, `config.${CHANNEL}.json`);
    if (!fs.existsSync(configFilePath)) {
        throw new Error(`Cannot found config file: ${configFilePath}`);
    }
    return configFilePath;
};
export const getFaucetConfig = () => {
    const configFilePath = getFaucetConfigPath();
    const config = JSON.parse(fs.readFileSync(configFilePath).toString());
    return config;
};
