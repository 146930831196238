import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { sendTransaction } from "@parrot/programs";
import { u64 } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import axios from "axios";
import BN from "bn.js";
import { ZAP_URL } from "config/constants";
var fromJSONModifiers = {
    PublicKey: function(data) {
        return new PublicKey(data);
    },
    BN: function(data) {
        return new BN(data);
    },
    Buffer: function(data) {
        return Buffer.from(data, "hex");
    },
    u64: function(data) {
        return new u64(data);
    }
};
export function fromJSON(obj) {
    if (obj == null) {
        return obj;
    }
    if (Array.isArray(obj)) {
        return Array.prototype.map.call(obj, function(element) {
            return fromJSON(element);
        });
    }
    if (typeof obj == "object") {
        var modifier = fromJSONModifiers[obj === null || obj === void 0 ? void 0 : obj.type];
        if (modifier) {
            return modifier(obj.data);
        }
        var newobj = {};
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = Object.entries(obj)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var tuple = _step.value;
                var _tuple = _sliced_to_array(tuple, 2), k = _tuple[0], v = _tuple[1];
                newobj[k] = fromJSON(v);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        return newobj;
    }
    return obj;
}
export function usedSigners(ixs, keypairs) {
    var usedSigners = ixs.map(function(i) {
        return i.keys.filter(function(k) {
            return k.isSigner;
        });
    }).flat().map(function(i) {
        return i.pubkey.toString();
    });
    return keypairs.filter(function(i) {
        return usedSigners.includes(i.publicKey.toString());
    });
}
export function sendZAPTransaction(provider, scope, name, accounts, params) {
    return _sendZAPTransaction.apply(this, arguments);
}
function _sendZAPTransaction() {
    _sendZAPTransaction = _async_to_generator(regeneratorRuntime.mark(function _callee(provider, scope, name, accounts, params) {
        var signers, data, op, ixs, ref, txId, transaction, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    signers = _args.length > 5 && _args[5] !== void 0 ? _args[5] : [];
                    _ctx.next = 3;
                    return axios.post("".concat(ZAP_URL, "/").concat(scope, "/").concat(name, "?prepare=true"), // `${ZAP_URL}/api/${name}?prepare=true`, // debug with localhost url
                    {
                        accounts: accounts,
                        params: [
                            params
                        ]
                    });
                case 3:
                    data = _ctx.sent.data;
                    op = fromJSON(data);
                    ixs = _to_consumable_array(op.prepare).concat(_to_consumable_array(op.main));
                    _ctx.next = 8;
                    return sendTransaction(provider, ixs, op.cleanup, usedSigners(ixs, signers), true, false);
                case 8:
                    ref = _ctx.sent;
                    txId = ref.txId;
                    transaction = ref.transaction;
                    return _ctx.abrupt("return", {
                        txId: txId,
                        transaction: transaction
                    });
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return _sendZAPTransaction.apply(this, arguments);
}
