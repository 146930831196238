import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { TOKENS } from "@parrot/programs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "utils/apiClient";
import earnConfig from "../../earnConfig.json";
var initialState = {
    vaults: earnConfig.vaults.map(function(vault) {
        return _object_spread_props(_object_spread({}, vault), {
            name: vault.name,
            iouToken: vault.iouMint,
            iouTokenInfo: TOKENS[vault.iouMint],
            iouTokenSupply: "0",
            depositToken: vault.depositMint,
            depositTokenInfo: TOKENS[vault.depositMint],
            investedAmount: "0",
            depositHolderAmount: "0",
            totalDepositFloat: "0",
            earnInfo: {
                type: "apy",
                rateFloat: 0
            }
        });
    }),
    vaultsSynced: false
};
export var fetchEarnVaults = createAsyncThunk("earn/types", _async_to_generator(regeneratorRuntime.mark(function _callee() {
    var data;
    return regeneratorRuntime.wrap(function _callee$(_ctx) {
        while(1)switch(_ctx.prev = _ctx.next){
            case 0:
                _ctx.next = 2;
                return apiClient.get("/earn/types");
            case 2:
                data = _ctx.sent.data;
                return _ctx.abrupt("return", data);
            case 4:
            case "end":
                return _ctx.stop();
        }
    }, _callee);
})));
var earnSlice = createSlice({
    name: "earn",
    initialState: initialState,
    reducers: {
    },
    extraReducers: function(builder) {
        builder.addCase(fetchEarnVaults.fulfilled, function(state, action) {
            state.vaults = action.payload.map(function(i) {
                return _object_spread_props(_object_spread({}, i), {
                    iouTokenInfo: TOKENS[i.iouToken],
                    depositTokenInfo: TOKENS[i.depositToken]
                });
            });
        });
    }
});
// Actions
export var earnReducer = earnSlice.reducer;
