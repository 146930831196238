import BigNumber from 'bignumber.js';
import { ZERO } from '../../anchor';
export const getLiquidationPriceInDebtToken = (debtAmount = ZERO.toString(), collateralAmount = ZERO.toString(), debtTokenDecimals, collateralTokenDecimals, liquidationRatio = ZERO.toString()) => {
    const debtAmountBN = new BigNumber(debtAmount);
    const collateralAmountBN = new BigNumber(collateralAmount);
    const liquidationRatioBN = new BigNumber(liquidationRatio);
    if (liquidationRatioBN.eq(ZERO) ||
        debtAmountBN.eq(ZERO) ||
        collateralAmountBN.eq(ZERO)) {
        return undefined;
    }
    const debtAmountFixed = debtAmountBN.dividedBy(new BigNumber(10).pow(debtTokenDecimals));
    const collateralAmountFixed = collateralAmountBN.dividedBy(new BigNumber(10).pow(collateralTokenDecimals));
    // liquidation_price_in_debt = collateral_amount / debt_amount / liquidation_ratio * PERCENT_MULTIPLE (10000)
    const liquidationPriceInDebt = collateralAmountFixed
        .multipliedBy(10000)
        .dividedBy(debtAmountFixed)
        .dividedBy(liquidationRatioBN);
    return BigNumber.max(liquidationPriceInDebt.isNaN() ? ZERO : liquidationPriceInDebt, ZERO).toString();
};
