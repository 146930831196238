import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { DecimalUtil } from "@parrotfi/core-sdk";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PublicKey } from "@solana/web3.js";
import BigNumber from "bignumber.js";
import { apiClient } from "../../utils/apiClient";
var initialState = {
    balances: {}
};
export var fetchBalance = createAsyncThunk("balances/owner/mint", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var ownerAddress, token, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ownerAddress = param.ownerAddress, token = param.token;
                    _ctx.next = 3;
                    return apiClient.get("/balances/".concat(ownerAddress, "/").concat(token.address));
                case 3:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", _object_spread_props(_object_spread({}, data), {
                        ownerAddress: ownerAddress,
                        token: token
                    }));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(_) {
        return _ref.apply(this, arguments);
    };
}());
var balanceSlice = createSlice({
    name: "balance",
    initialState: initialState,
    reducers: {
        clearBalances: function(state) {
            state.balances = {};
        }
    },
    extraReducers: function(builder) {
        builder.addCase(fetchBalance.fulfilled, function(state, action) {
            var _payload = action.payload, address = _payload.address, amount = _payload.amount, ownerAddress = _payload.ownerAddress, token = _payload.token;
            var balance = new BigNumber(amount || "0");
            state.balances = _object_spread_props(_object_spread({}, state.balances), _define_property({}, "".concat(ownerAddress, "_").concat(token.address), {
                address: address ? new PublicKey(address) : undefined,
                mint: token.address,
                amount: balance,
                decimals: token.decimals,
                uiAmount: DecimalUtil.fromString(balance.toString(), token.decimals)
            }));
        });
    }
});
// Actions
export var clearBalances = balanceSlice.actions.clearBalances, balanceReducer = balanceSlice.reducer;
