import BigNumber from 'bignumber.js';
import { ONE, SLOT_IN_YEAR } from '../../anchor';
export const getDebtWithPrediction = (debtAmount, durationSlot, interestRatePerYear) => {
    if (!interestRatePerYear) {
        return debtAmount;
    }
    const debtAmountBN = new BigNumber(debtAmount);
    const interestRateBN = new BigNumber(interestRatePerYear);
    const durationRate = interestRateBN
        .multipliedBy(durationSlot)
        .dividedBy(SLOT_IN_YEAR);
    return debtAmountBN.multipliedBy(durationRate.plus(ONE)).toString();
};
