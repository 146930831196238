var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// import { PublicKey } from '@solana/web3.js';
import { web3 } from '@project-serum/anchor';
import BigNumber from 'bignumber.js';
import { ZERO } from '../../anchor';
import { EARN_PROGRAM_ID, VAULT_SEED_PREFIX } from '../sdk/constants';
export function findVaultSigner(vaultAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield web3.PublicKey.findProgramAddress([Buffer.from(VAULT_SEED_PREFIX), vaultAddress.toBuffer()], EARN_PROGRAM_ID);
    });
}
export function calculateEarnDepositAmount(iouTotalSupplyRaw, depositHolderAmountRaw, vaultInvestedAmountRaw, amountRaw) {
    const iouTotalSupply = new BigNumber(iouTotalSupplyRaw);
    const vaultInvestedAmount = new BigNumber(vaultInvestedAmountRaw);
    const depositHolderAmount = new BigNumber(depositHolderAmountRaw);
    const amount = new BigNumber(amountRaw);
    if (amount.isZero()) {
        return ZERO;
    }
    if (iouTotalSupply.isZero()) {
        return ZERO;
    }
    const totalDepositValue = vaultInvestedAmount.plus(depositHolderAmount);
    if (totalDepositValue.isZero()) {
        return amount;
    }
    // Deposit should maintain the per iou value.
    //
    // iou_amount / deposit_amount == iouTotalSupply / total_deposit_value
    //
    // After deposit:
    //
    // (iouTotalSupply + iou_amount) / (total_deposit_value + deposit_amount)
    const iouAmount = new BigNumber(iouTotalSupply)
        .multipliedBy(amount)
        .dividedBy(totalDepositValue);
    return iouAmount.isNaN() ? ZERO : iouAmount;
}
export function calculateEarnWithdrawAmount(iouTotalSupplyRaw, depositHolderAmountRaw, vaultInvestedAmountRaw, amountRaw) {
    const iouTotalSupply = new BigNumber(iouTotalSupplyRaw);
    const vaultInvestedAmount = new BigNumber(vaultInvestedAmountRaw);
    const depositHolderAmount = new BigNumber(depositHolderAmountRaw);
    const amount = new BigNumber(amountRaw);
    if (amount.isZero()) {
        return ZERO;
    }
    if (iouTotalSupply.isZero()) {
        return ZERO;
    }
    const totalDepositValue = vaultInvestedAmount.plus(depositHolderAmount);
    if (totalDepositValue.isZero()) {
        return amount;
    }
    const withdrawAmount = new BigNumber(totalDepositValue)
        .multipliedBy(amountRaw)
        .dividedBy(iouTotalSupply);
    return withdrawAmount.isNaN() ? ZERO : withdrawAmount;
}
