import { useEffect } from "react";
import { clearBalances, clearOwnerVaults, clearRewards, clearStakingAccounts, clearVesting, useAppDispatch } from "state";
import { useWalletProvider } from "./useWalletProvider";
var useWalletDisconnect = function() {
    var connected = useWalletProvider().connected;
    var dispatch = useAppDispatch();
    useEffect(function() {
        if (connected === false) {
            dispatch(clearOwnerVaults());
            dispatch(clearBalances());
            dispatch(clearStakingAccounts());
            dispatch(clearRewards());
            dispatch(clearVesting());
        }
    }, [
        connected
    ]);
};
export default useWalletDisconnect;
