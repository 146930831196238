import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { StakeProgram, SystemProgram } from '@solana/web3.js';
import { EARN_PROGRAM_ID } from '../../earn';
import { AnchorParrotIDL, PRT_PROGRAM_ID, PRT_PROGRAM_ID_DEV } from '../../parrot';
import { STAKE_POOL_PROGRAM_ID } from '../../stake';
import { VESTING_PROGRAM_ID } from '../../vesting';
export const PROGRAMS_NAMES = {
    [TOKEN_PROGRAM_ID.toString()]: 'Token program',
    [SystemProgram.programId.toString()]: 'System program',
    [StakeProgram.programId.toString()]: 'Stake program',
    [PRT_PROGRAM_ID.toString()]: 'Parrot program',
    [PRT_PROGRAM_ID_DEV.toString()]: 'Parrot devnet program',
    [STAKE_POOL_PROGRAM_ID.toString()]: 'Parrot Pool program',
    [VESTING_PROGRAM_ID.toString()]: 'Parrot Vesting program',
    [EARN_PROGRAM_ID.toString()]: 'Parrot Earn program'
};
export const PROGRAMS_ERRORS = {
    [PRT_PROGRAM_ID.toString()]: AnchorParrotIDL.errors,
    [STAKE_POOL_PROGRAM_ID.toString()]: [
        {
            code: 0,
            msg: 'The account cannot be initialized because it is already being used.',
            name: 'AlreadyInUse'
        },
        {
            code: 1,
            msg: "The program address provided doesn't match the value generated by the program.",
            name: 'InvalidProgramAddress'
        },
        {
            code: 2,
            msg: 'The stake pool state is invalid.',
            name: 'InvalidState'
        },
        {
            code: 3,
            msg: 'The calculation failed.',
            name: 'CalculationFailure'
        },
        {
            code: 4,
            msg: 'Stake pool fee > 1.',
            name: 'FeeTooHigh'
        },
        {
            code: 5,
            msg: 'Token account is associated with the wrong mint.',
            name: 'WrongAccountMint'
        },
        {
            code: 6,
            msg: 'Wrong pool manager account.',
            name: 'WrongManager'
        },
        {
            code: 7,
            msg: 'Required signature is missing.',
            name: 'SignatureMissing'
        },
        {
            code: 8,
            msg: 'Invalid validator stake list account.',
            name: 'InvalidValidatorStakeList'
        },
        {
            code: 9,
            msg: 'Invalid manager fee account.',
            name: 'InvalidFeeAccount'
        },
        {
            code: 10,
            msg: 'Specified pool mint account is wrong.',
            name: 'WrongPoolMint'
        },
        {
            code: 11,
            msg: 'Stake account is not in the state expected by the program.',
            name: 'WrongStakeState'
        },
        {
            code: 12,
            msg: 'User stake is not active',
            name: 'UserStakeNotActive'
        },
        {
            code: 13,
            msg: 'Stake account voting for this validator already exists in the pool.',
            name: 'ValidatorAlreadyAdded'
        },
        {
            code: 14,
            msg: 'Stake account for this validator not found in the pool.',
            name: 'ValidatorNotFound'
        },
        {
            code: 15,
            msg: 'Stake account address not properly derived from the validator address.',
            name: 'InvalidStakeAccountAddress'
        },
        {
            code: 16,
            msg: 'Identify validator stake accounts with old balances and update them.',
            name: 'StakeListOutOfDate'
        },
        {
            code: 17,
            msg: 'First update old validator stake account balances and then pool stake balance.',
            name: 'StakeListAndPoolOutOfDate'
        },
        {
            code: 18,
            msg: 'Validator stake account is not found in the list storage.',
            name: 'UnknownValidatorStakeAccount'
        },
        {
            code: 19,
            msg: 'Wrong minting authority set for mint pool account',
            name: 'WrongMintingAuthority'
        },
        {
            code: 20,
            msg: 'The size of the given validator stake list does match the expected amount',
            name: 'UnexpectedValidatorListAccountSize'
        },
        {
            code: 21,
            msg: 'Wrong pool staker account.',
            name: 'WrongStaker'
        },
        {
            code: 22,
            msg: 'Pool token supply is not zero on initialization',
            name: 'NonZeroPoolTokenSupply'
        },
        {
            code: 23,
            msg: 'The lamports in the validator stake account is not equal to the minimum',
            name: 'StakeLamportsNotEqualToMinimum'
        },
        {
            code: 24,
            msg: 'The provided deposit stake account is not delegated to the preferred deposit vote account',
            name: 'IncorrectDepositVoteAddress'
        },
        {
            code: 25,
            msg: 'The provided withdraw stake account is not the preferred deposit vote account',
            name: 'IncorrectWithdrawVoteAddress'
        },
        {
            code: 26,
            msg: 'The mint has an invalid freeze authority',
            name: 'InvalidMintFreezeAuthority'
        },
        {
            code: 27,
            msg: 'Proposed fee increase exceeds stipulated ratio',
            name: 'FeeIncreaseTooHigh'
        },
        {
            code: 28,
            msg: 'Not enough pool tokens provided to withdraw stake with one lamport',
            name: 'WithdrawalTooSmall'
        },
        {
            code: 29,
            msg: 'Not enough lamports provided for deposit to result in one pool token',
            name: 'DepositTooSmall'
        },
        {
            code: 30,
            msg: "Provided stake deposit authority does not match the program's",
            name: 'FeeIncreaseTooHigh'
        },
        {
            code: 31,
            msg: "Provided sol deposit authority does not match the program's",
            name: 'InvalidSolDepositAuthority'
        }
    ]
};
export const KNOWN_GENERIC_ERRORS = [
    {
        msg: 'Insufficient balance or debt celling reach. Check your SOL and collateral token balance and try again',
        name: 'InsufficientBalance',
        code: 1
    },
    {
        msg: 'Unknown',
        name: 'Unknown',
        code: 3
    }
];
