import { web3 } from '@project-serum/anchor';
import { deserialize, serialize } from 'borsh';
export const boolMapper = {
    encode: (t) => {
        if (t) {
            return 1;
        }
        else {
            return 0;
        }
    },
    decode: (i) => {
        if (i == 0) {
            return false;
        }
        else {
            return true;
        }
    }
};
export const pubkeyMapper = {
    encode: (key) => {
        return key.toBuffer();
    },
    decode: (buf) => {
        return new web3.PublicKey(buf);
    }
};
// support strings that can be contained in at most 32 bytes
export const str32Mapper = {
    encode: (str) => {
        str = str.substr(0, 32).padEnd(32);
        return Buffer.from(str, 'utf8').slice(0, 32); // truncate at 32 bytes
    },
    decode: (bytes) => {
        return Buffer.from(bytes).toString('utf8').trim();
    }
};
class Serialization {
    static deserialize(data) {
        if (this['decode']) {
            return this['decode'](deserialize(schema, this, data));
        }
        return deserialize(schema, this, data);
    }
    static serialize(data) {
        if (this['encode']) {
            return new this(this['encode'](data)).serialize();
        }
        return new this(data).serialize();
    }
    serialize() {
        const buf = Buffer.from(serialize(schema, this));
        if (buf.length == 0) {
            throw new Error('serialized buffer is 0. something wrong with schema');
        }
        return buf;
    }
    constructor(data) {
        Object.assign(this, data);
    }
}
class InstructionSerialization extends Serialization {
    serialize() {
        return new Instruction({ [this.constructor.name]: this }).serialize();
    }
}
export class AggregatorConfig extends Serialization {
    static decode(data) {
        return Object.assign(Object.assign({}, data), { description: str32Mapper.decode(data.description), rewardTokenAccount: pubkeyMapper.decode(data.rewardTokenAccount) });
    }
    static encode(data) {
        return new this(Object.assign(Object.assign({}, data), { description: str32Mapper.encode(data.description), rewardTokenAccount: pubkeyMapper.encode(data.rewardTokenAccount) }));
    }
}
AggregatorConfig.size = 81;
AggregatorConfig.schema = {
    kind: 'struct',
    fields: [
        ['description', [32]],
        ['decimals', 'u8'],
        ['roundTimeout', 'u32'],
        ['restartDelay', 'u8'],
        ['requesterRestartDelay', 'u8'],
        ['maxSubmissions', 'u8'],
        ['minSubmissions', 'u8'],
        ['rewardAmount', 'u64'],
        ['rewardTokenAccount', [32]]
    ]
};
export class Submission extends Serialization {
    static decode(data) {
        return Object.assign(Object.assign({}, data), { oracle: pubkeyMapper.decode(data.oracle) });
    }
}
Submission.schema = {
    kind: 'struct',
    fields: [
        ['updatedAt', 'u64'],
        ['value', 'u64'],
        ['oracle', [32]]
    ]
};
export class Submissions extends Serialization {
}
Submissions.size = 625;
Submissions.schema = {
    kind: 'struct',
    fields: [
        ['isInitialized', 'u8', boolMapper],
        ['submissions', [Submission, 13]]
    ]
};
export class Round extends Serialization {
}
Round.schema = {
    kind: 'struct',
    fields: [
        ['id', 'u64'],
        ['createdAt', 'u64'],
        ['updatedAt', 'u64']
    ]
};
export class Answer extends Serialization {
}
Answer.schema = {
    kind: 'struct',
    fields: [
        ['roundID', 'u64'],
        ['median', 'u64'],
        ['createdAt', 'u64'],
        ['updatedAt', 'u64']
    ]
};
export class Aggregator extends Serialization {
    static decode(data) {
        return Object.assign(Object.assign({}, data), { config: AggregatorConfig.decode(data.config), owner: pubkeyMapper.decode(data.owner), roundSubmissions: pubkeyMapper.decode(data.roundSubmissions), answerSubmissions: pubkeyMapper.decode(data.answerSubmissions) });
    }
}
Aggregator.size = 234;
Aggregator.schema = {
    kind: 'struct',
    fields: [
        ['config', AggregatorConfig],
        ['isInitialized', 'u8'],
        ['owner', [32]],
        ['round', Round],
        ['roundSubmissions', [32]],
        ['answer', Answer],
        ['answerSubmissions', [32]]
    ]
};
export class Oracle extends Serialization {
    static decode(data) {
        return Object.assign(Object.assign({}, data), { description: str32Mapper.decode(data.description), isInitialized: boolMapper.decode(data.isInitialized), aggregator: pubkeyMapper.decode(data.aggregator), owner: pubkeyMapper.decode(data.owner), submission: Submission.decode(data.submission) });
    }
}
Oracle.size = 169;
Oracle.schema = {
    kind: 'struct',
    fields: [
        ['description', [32]],
        ['isInitialized', 'u8'],
        ['withdrawable', 'u64'],
        ['allowStartRound', 'u64'],
        ['updatedAt', 'u64'],
        ['aggregator', [32]],
        ['owner', [32]],
        ['submission', Submission]
    ]
};
export class Initialize extends InstructionSerialization {
    static encode(data) {
        return {
            config: AggregatorConfig.encode(data.config)
        };
    }
}
Initialize.schema = {
    kind: 'struct',
    fields: [['config', AggregatorConfig]]
};
export class Configure extends InstructionSerialization {
    static encode(data) {
        return {
            config: AggregatorConfig.encode(data.config)
        };
    }
}
Configure.schema = {
    kind: 'struct',
    fields: [['config', AggregatorConfig]]
};
export class AddOracle extends InstructionSerialization {
    static encode(data) {
        return Object.assign(Object.assign({}, data), { description: str32Mapper.encode(data.description) });
    }
}
AddOracle.schema = {
    kind: 'struct',
    fields: [['description', [32]]]
};
export class TransferOwner extends InstructionSerialization {
    static encode(data) {
        return {
            newOwner: pubkeyMapper.encode(data.newOwner)
        };
    }
}
TransferOwner.schema = {
    kind: 'struct',
    fields: [['newOwner', [32]]]
};
export class Instruction extends Serialization {
    constructor(prop) {
        super({});
        for (const key of Object.keys(prop)) {
            this.enum = key;
            this[key] = prop[key];
            return;
        }
        throw new Error('not an expected enum object');
    }
    get value() {
        return this[this.enum];
    }
}
Instruction.schema = {
    kind: 'enum',
    field: 'enum',
    values: [
        [Initialize.name, Initialize],
        [Configure.name, Configure],
        [TransferOwner.name, TransferOwner],
        [AddOracle.name, AddOracle]
    ]
};
export const schema = new Map([
    [Round, Round.schema],
    [Answer, Answer.schema],
    [Submission, Submission.schema],
    [AggregatorConfig, AggregatorConfig.schema],
    [Aggregator, Aggregator.schema],
    [Oracle, Oracle.schema],
    // Operation
    [Instruction, Instruction.schema],
    [Initialize, Initialize.schema],
    [Configure, Configure.schema],
    [AddOracle, AddOracle.schema],
    [TransferOwner, TransferOwner.schema]
]);
