import { web3 } from '@project-serum/anchor';
export const STABLES_ORACLE = new web3.PublicKey('STABLEQRACLE1111111111111111111111111111111');
export const PYTH_ORACLE_PROGRAM_ID = new web3.PublicKey('FsJ3A3u2vn5cTVofAjvy6y5kwABJAqYWpe4975bi2epH');
export const PYTH_ORACLE_PROGRAM_ID_DEVNET = new web3.PublicKey('gSbePebfvPy7tRqimPoVecS2UsBvYv46ynrzWocc92s');
export const FLUX_ORACLE_PROGRAM_ID = new web3.PublicKey('3rVEgTR97opXeV2DPaHbyj4MTXbTWcQ3eeUcgdcmKkyT');
export const FLUX_ORACLE_PROGRAM_ID_DEVNET = new web3.PublicKey('3Lur6drN1FQq4VArst1ck8mYbbzaSJAysW8oahHrMeuL');
export const SWITCHBOARD_ORACLE_PROGRAM_ID = new web3.PublicKey('SW1TCH7qEPTdLsDHRgPuMQjbQxKdH2aBStViMFnt64f');
export const SWITCHBOARD_ORACLE_PROGRAM_ID_DEVNET = new web3.PublicKey('2TfB33aLaneQb5TNVwyDz3jSZXS6jdW2ARw1Dgf84XCG');
export const RECIP_ORACLES_PAIRS = ['usdc:btc'];
export const USD_ORACLE_PAIRS = ['usd:usd', 'usdt:pai', 'usdc:pai'];
