import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { estimatedVestingClaimable, TOKENS, ZERO } from "@parrot/programs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "utils/apiClient";
import vestingConfig from "../../vestingConfig.json";
var initialVesting = {
    vestConfig: vestingConfig.vestConfig,
    halfLifeDuration: 0,
    claimAllDuration: 0,
    vestToken: vestingConfig.vestMint,
    vestTokenInfo: TOKENS[vestingConfig.vestMint],
    claimToken: vestingConfig.claimableMint,
    claimTokenInfo: TOKENS[vestingConfig.claimableMint],
    totalStaked: "0",
    totalStakedFloat: "0",
    totalVested: "0",
    totalVestedFloat: "0",
    rewards: []
};
var initialState = {
    vesting: initialVesting,
    estimatedClaimableAmount: ZERO
};
export var fetchVesting = createAsyncThunk("vesting/fetch", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(ownerAddress) {
        var data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return apiClient.get("/vesting/".concat(ownerAddress !== null && ownerAddress !== void 0 ? ownerAddress : ""));
                case 2:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(ownerAddress) {
        return _ref.apply(this, arguments);
    };
}());
var vestingSlice = createSlice({
    name: "vesting",
    initialState: initialState,
    reducers: {
        clearVesting: function(state) {
            state.vesting = initialVesting;
            state.estimatedClaimableAmount = ZERO;
        },
        updateVestingClaimableAmount: function(state, action) {
            var ref;
            if (((ref = action.payload) === null || ref === void 0 ? void 0 : ref.amount) && state.vesting && state.vesting.halfLifeDuration && state.vesting.lastUpdatedTime && state.vesting.lastVestTime && state.vesting.claimableAmount) {
                var amount = estimatedVestingClaimable(state.vesting.halfLifeDuration, state.vesting.claimAllDuration, action.payload.amount.toNumber(), state.vesting.lastUpdatedTime, state.vesting.lastVestTime, state.vesting.claimableAmount, Math.floor(Date.now() / 1000));
                state.estimatedClaimableAmount = amount.decimalPlaces(state.vesting.vestTokenInfo.decimals);
            }
        }
    },
    extraReducers: function(builder) {
        builder.addCase(fetchVesting.fulfilled, function(state, action) {
            state.vesting = _object_spread_props(_object_spread({}, action.payload), {
                vestTokenInfo: TOKENS[action.payload.vestToken],
                claimTokenInfo: TOKENS[action.payload.claimToken]
            });
        });
    }
});
var _actions = vestingSlice.actions;
// Actions
export var vestingReducer = vestingSlice.reducer, clearVesting = _actions.clearVesting, updateVestingClaimableAmount = _actions.updateVestingClaimableAmount;
