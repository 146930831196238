import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import { balanceReducer, clearBalances, fetchBalance } from "./reducers/balances";
import { earnReducer, fetchEarnVaults } from "./reducers/earn";
import { notificationReducer, notifyRemove, notifyShow } from "./reducers/notifications";
import { priceReducer } from "./reducers/prices";
import { clearRewards, fetchRewards, rewardsReducer } from "./reducers/rewards";
import { clearStakingAccounts, fetchStakingAccounts, fetchStakingPools, stakingReducer } from "./reducers/staking";
import { fetchStatsTvl, statsReducer } from "./reducers/stats";
import { addTransaction, fetchTransactions, removeTransaction, transactionReducer } from "./reducers/transactions";
import { clearOwnerVaults, fetchOwnerVault, fetchOwnerVaults, fetchVaultTypes, vaultReducer } from "./reducers/vaults";
import { clearVesting, fetchVesting, updateVestingClaimableAmount, vestingReducer } from "./reducers/vesting";
var store = configureStore({
    devTools: "production" !== "production",
    middleware: function(getDefaultMiddleware) {
        return getDefaultMiddleware({
            thunk: {
                extraArgument: {}
            },
            logger: logger,
            serializableCheck: false
        });
    },
    reducer: {
        stats: statsReducer,
        vault: vaultReducer,
        earn: earnReducer,
        price: priceReducer,
        staking: stakingReducer,
        rewards: rewardsReducer,
        vesting: vestingReducer,
        balance: balanceReducer,
        transaction: transactionReducer,
        notification: notificationReducer
    }
});
export var useAppDispatch = function() {
    return useDispatch();
};
export var useAppSelector = function(selector) {
    return useSelector(selector);
};
export { addTransaction, fetchTransactions, removeTransaction };
export { clearOwnerVaults, fetchOwnerVault, fetchOwnerVaults, fetchVaultTypes };
export { fetchStatsTvl };
export { clearBalances, fetchBalance };
export { clearStakingAccounts, fetchStakingAccounts, fetchStakingPools };
export { clearRewards, fetchRewards };
export { clearVesting, fetchVesting, updateVestingClaimableAmount };
export { notifyRemove, notifyShow };
export { fetchEarnVaults };
export default store;
