var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { web3 } from '@project-serum/anchor';
import { getParsedMultipleAccountsInfo } from '../../anchor';
import { getStakeConfig } from '../configs';
import { getParsedStakingAccount } from '../utils';
import { findStakeProgramAddress, getStakePool, getValidatorList } from './accounts';
export class StakePoolProgram {
    constructor(provider, overrideConfig) {
        this.provider = provider;
        this.stakeConfig = overrideConfig !== null && overrideConfig !== void 0 ? overrideConfig : getStakeConfig();
    }
    /**
     * Stake pool program ID
     *
     * @readonly
     * @memberof StakePoolProgram
     */
    get programId() {
        return new web3.PublicKey(this.stakeConfig.programId);
    }
    /**
     * Parrot staking Pool config
     *
     * @readonly
     * @memberof StakePoolProgram
     */
    get parrotStakePool() {
        return {
            poolAccount: new web3.PublicKey(this.stakeConfig.stakePoolAddress),
            poolValidatorListAccount: new web3.PublicKey(this.stakeConfig.validatorListStorage),
            poolTokenMint: new web3.PublicKey(this.stakeConfig.poolTokenMint)
        };
    }
    /**
     * Return user Parrot Stake pool Accounts
     * @returns
     */
    getStakingAccounts(owner) {
        return __awaiter(this, void 0, void 0, function* () {
            return getParsedStakingAccount(this.provider.connection, {
                stakeAuthority: owner,
                withdrawAuthority: null
            });
        });
    }
    /**
     * Return validator stake Accounts
     * @returns
     */
    getValidatorsStakeAccounts(voteAccounts, stakePoolAddress) {
        var voteAccounts_1, voteAccounts_1_1;
        var e_1, _a;
        return __awaiter(this, void 0, void 0, function* () {
            const validatorStakeAccounts = [];
            try {
                for (voteAccounts_1 = __asyncValues(voteAccounts); voteAccounts_1_1 = yield voteAccounts_1.next(), !voteAccounts_1_1.done;) {
                    const voteAccountAddress = voteAccounts_1_1.value;
                    const [validatorStakeAccountAddress] = yield findStakeProgramAddress(this.programId, voteAccountAddress, stakePoolAddress);
                    validatorStakeAccounts.push(validatorStakeAccountAddress);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (voteAccounts_1_1 && !voteAccounts_1_1.done && (_a = voteAccounts_1.return)) yield _a.call(voteAccounts_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            const accounts = yield getParsedMultipleAccountsInfo(this.provider.connection, validatorStakeAccounts);
            return accounts;
        });
    }
    /**
     *
     * @param address stake pool account address
     * @returns
     */
    getStakePoolAccount(address) {
        return __awaiter(this, void 0, void 0, function* () {
            return getStakePool(this.provider.connection, address);
        });
    }
    /**
     *
     * @param address stake pool validator list account address
     * @returns
     */
    getStakePoolValidatorListAccount(address) {
        return __awaiter(this, void 0, void 0, function* () {
            return getValidatorList(this.provider.connection, address);
        });
    }
}
