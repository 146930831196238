var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseInstructionError } from '../errors';
import { sleep } from './sleep';
export const confirmTransaction = (provider, txId, transaction, commitment = 'confirmed') => __awaiter(void 0, void 0, void 0, function* () {
    let hashExpired = false;
    let txSuccess = false;
    const START_TIME = new Date();
    const blockhashResponse = yield provider.connection.getLatestBlockhashAndContext('finalized');
    const lastValidHeight = blockhashResponse.value.lastValidBlockHeight;
    while (!hashExpired && !txSuccess) {
        const { value: statuses } = yield provider.connection.getSignatureStatuses([
            txId
        ]);
        if (!statuses || statuses.length === 0) {
            throw new Error('Failed to get signature status');
        }
        const status = statuses[0];
        if (status === null || status === void 0 ? void 0 : status.err) {
            throw parseInstructionError(transaction, txId, status.err);
        }
        // Break loop if transaction has succeeded
        if (status &&
            (status.confirmationStatus === 'confirmed' ||
                status.confirmationStatus === 'finalized')) {
            txSuccess = true;
            const endTime = new Date();
            const elapsed = (endTime.getTime() - START_TIME.getTime()) / 1000;
            console.log(`Transaction Success. Elapsed time: ${elapsed} seconds.`);
            console.log(`https://explorer.solana.com/tx/${txId}`);
            break;
        }
        hashExpired = yield isBlockhashExpired(provider.connection, lastValidHeight);
        // Break loop if blockhash has expired
        if (hashExpired) {
            const endTime = new Date();
            const elapsed = (endTime.getTime() - START_TIME.getTime()) / 1000;
            console.log(`Blockhash has expired. Elapsed time: ${elapsed} seconds.`);
            // (add your own logic to Fetch a new blockhash and resend the transaction or throw an error)
            break;
        }
        // Check again after 2.5 sec
        yield sleep(2500);
    }
    if (!txSuccess) {
        throw new Error('Transaction failed, try to send it again');
    }
    return txId;
});
function isBlockhashExpired(connection, lastValidBlockHeight) {
    return __awaiter(this, void 0, void 0, function* () {
        const currentBlockHeight = yield connection.getBlockHeight('finalized');
        // console.log('                           ');
        // console.log('Current Block height:             ', currentBlockHeight);
        // console.log(
        //   'Last Valid Block height - 150:     ',
        //   lastValidBlockHeight - 150
        // );
        // console.log('--------------------------------------------');
        // console.log(
        //   'Difference:                      ',
        //   currentBlockHeight - (lastValidBlockHeight - 150)
        // ); // If Difference is positive, blockhash has expired.
        // console.log('                           ');
        return currentBlockHeight > lastValidBlockHeight - 150;
    });
}
