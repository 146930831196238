import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "utils/apiClient";
var initialState = {
    prices: {}
};
export var fetchPrice = createAsyncThunk("prices/mint", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(priceOracle) {
        var data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return apiClient.get("/prices/".concat(priceOracle));
                case 2:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(priceOracle) {
        return _ref.apply(this, arguments);
    };
}());
var priceSlice = createSlice({
    name: "price",
    initialState: initialState,
    reducers: {},
    extraReducers: function(builder) {
        builder.addCase(fetchPrice.fulfilled, function(state, action) {
            var priceOracle = action.payload.address;
            state.prices = _object_spread_props(_object_spread({}, state.prices), _define_property({}, priceOracle, action.payload));
        });
    }
});
// Actions
export var priceReducer = priceSlice.reducer;
