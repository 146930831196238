import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import { ToastTypes } from "components/toast";
import { notifyShow, useAppDispatch } from "state";
var useToast = function() {
    var dispatch = useAppDispatch();
    var toastError = function(param) {
        var title = param.title, _message = param.message, message = _message === void 0 ? "" : _message, error = param.error;
        var errorMessage = "";
        if (_instanceof(error, Error)) {
            var _message1;
            errorMessage = "\n".concat((_message1 = error.message) !== null && _message1 !== void 0 ? _message1 : error);
        } else if (error) {
            errorMessage = "\n".concat(error);
        }
        dispatch(notifyShow({
            type: ToastTypes.FAILURE,
            title: title,
            message: "".concat(message).concat(errorMessage)
        }));
    };
    var toastInfo = function(param) {
        var title = param.title, _message = param.message, message = _message === void 0 ? "" : _message;
        dispatch(notifyShow({
            type: ToastTypes.INFO,
            title: title,
            message: message
        }));
    };
    var toastSuccess = function(param) {
        var title = param.title, _message = param.message, message = _message === void 0 ? "" : _message, txId = param.txId;
        dispatch(notifyShow({
            type: ToastTypes.SUCCESS,
            title: title,
            message: message,
            txId: txId
        }));
    };
    var toastWarning = function(param) {
        var title = param.title, _message = param.message, message = _message === void 0 ? "" : _message;
        dispatch(notifyShow({
            type: ToastTypes.WARNING,
            title: title,
            message: message
        }));
    };
    return {
        toastError: toastError,
        toastInfo: toastInfo,
        toastSuccess: toastSuccess,
        toastWarning: toastWarning
    };
};
export { useToast };
