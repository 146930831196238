// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
var SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
var VERSION = process.env.VERSION;
if (process.env.CHANNEL === "prod") {
    Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        release: VERSION
    });
}
