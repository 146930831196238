var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BN, Program, web3 } from '@project-serum/anchor';
import { confirmTransaction, createAssociatedTokenAccountInstrs, findAssociatedTokenAccount, sendTransaction } from '../../anchor';
import { getEarnConfig } from '../configs';
import { createEarnDepositInstr, createEarnWithdrawInstr } from '../instructions';
export class EarnProgram {
    constructor(provider, overrideConfig) {
        this.provider = provider;
        this.simulate = false;
        this.earnConfig = overrideConfig !== null && overrideConfig !== void 0 ? overrideConfig : getEarnConfig();
        this.program = new Program(this.earnConfig.programIdl, this.programId, provider);
    }
    /**
     * Vesting program ID
     *
     * @readonly
     * @memberof EarnProgram
     */
    get programId() {
        return new web3.PublicKey(this.earnConfig.programId);
    }
    confirmTransaction(transaction, txId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield confirmTransaction(this.provider, txId, transaction, 'confirmed');
        });
    }
    getVault(publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const account = yield this.program.account.vault.fetch(publicKey);
            if (!account) {
                throw new Error(`Earn vault account not found for ${publicKey}`);
            }
            return Object.assign(Object.assign({}, account), { publicKey });
        });
    }
    /**
     *
     * @returns
     */
    getVaults() {
        return __awaiter(this, void 0, void 0, function* () {
            const vaults = this.earnConfig.vaults;
            return vaults;
        });
    }
    deposit(vaultConfig, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            const vault = new web3.PublicKey(vaultConfig.address);
            const depositMint = new web3.PublicKey(vaultConfig.depositMint);
            const iouMint = new web3.PublicKey(vaultConfig.iouMint);
            if (amount.isZero()) {
                throw new Error('deposit amount must be greater than zero');
            }
            let userIouTokenAccount = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, iouMint);
            if (!userIouTokenAccount) {
                const { instructions: createTokenAccountInstructions, signers: createTokenAccountSigners, extra: { tokenAccount } } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: iouMint,
                    owner: this.provider.wallet.publicKey
                });
                userIouTokenAccount = tokenAccount;
                instructions.push(...createTokenAccountInstructions);
                signers.push(...createTokenAccountSigners);
            }
            const depositInstrs = yield createEarnDepositInstr(this.program, vault, depositMint, iouMint, this.provider.wallet.publicKey, new BN(amount.toString()));
            instructions.push(depositInstrs);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers, true, this.simulate);
            console.log('Earn Deposit txId', txId);
            return { txId, transaction };
        });
    }
    withdraw(vaultConfig, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            const vault = new web3.PublicKey(vaultConfig.address);
            const depositMint = new web3.PublicKey(vaultConfig.depositMint);
            const iouMint = new web3.PublicKey(vaultConfig.iouMint);
            if (amount.isZero()) {
                throw new Error('withdraw amount must be greater than zero');
            }
            let userDepositTokenAccount = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, depositMint);
            if (!userDepositTokenAccount) {
                const { instructions: createTokenAccountInstructions, signers: createTokenAccountSigners, extra: { tokenAccount } } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: depositMint,
                    owner: this.provider.wallet.publicKey
                });
                userDepositTokenAccount = tokenAccount;
                instructions.push(...createTokenAccountInstructions);
                signers.push(...createTokenAccountSigners);
            }
            const depositInstrs = yield createEarnWithdrawInstr(this.program, vault, depositMint, iouMint, this.provider.wallet.publicKey, new BN(amount.toString()));
            instructions.push(depositInstrs);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers, true, this.simulate);
            console.log('Earn Withdraw txId', txId);
            return { txId, transaction };
        });
    }
}
