import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { DecimalUtil } from "@parrotfi/core-sdk";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiClient } from "utils/apiClient";
function safeSet(obj, key, value) {
    if (!obj) {
        obj = {};
    } else if (!obj[key]) {
        obj[key] = [
            value
        ];
    } else {
        obj[key].push(value);
    }
}
var initialState = {
    endedRewards: {},
    ongoingRewards: {}
};
export var fetchRewards = createAsyncThunk("rewards/fetch", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(ownerAddress) {
        var data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return apiClient.get("/rewards/".concat(ownerAddress));
                case 2:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(ownerAddress) {
        return _ref.apply(this, arguments);
    };
}());
var rewardsSlice = createSlice({
    name: "rewards",
    initialState: initialState,
    reducers: {
        clearRewards: function(state) {
            state.endedRewards = {};
            state.ongoingRewards = {};
        }
    },
    extraReducers: function(builder) {
        builder.addCase(fetchRewards.fulfilled, function(state, action) {
            var endedRewardsByCampaign = {};
            var onGoingRewardsByCampaign = {};
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = action.payload[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var record = _step.value;
                    var formattedReward = {
                        id: "".concat(record.campaign.id, "_").concat(record.token.address),
                        campaign: record.campaign,
                        token: record.token,
                        rewardAmountFloat: DecimalUtil.fromNumber(record.rewardAmount - record.lastHourRewardAmount, record.token.decimals).toString(),
                        rewardAmountFloatPerDay: DecimalUtil.fromNumber(record.lastHourRewardAmount * 24, record.token.decimals).toString()
                    };
                    if (formattedReward.campaign.isEnded) {
                        safeSet(endedRewardsByCampaign, formattedReward.campaign.id.toString(), formattedReward);
                    } else {
                        safeSet(onGoingRewardsByCampaign, formattedReward.campaign.id.toString(), formattedReward);
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
            state.endedRewards = endedRewardsByCampaign;
            state.ongoingRewards = onGoingRewardsByCampaign;
        });
    }
});
// Actions
export var rewardsReducer = rewardsSlice.reducer, clearRewards = rewardsSlice.actions.clearRewards;
