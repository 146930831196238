var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
export function getAssociatedTokenAddress(owner, tokenMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const associatedAddress = yield Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, tokenMint, owner, true);
        return associatedAddress;
    });
}
export function findAssociatedTokenAccount(provider, owner, tokenMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const associatedAddress = yield getAssociatedTokenAddress(owner, tokenMint);
        const associatedAccount = yield provider.connection.getAccountInfo(associatedAddress);
        return associatedAccount ? associatedAddress : undefined;
    });
}
