var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { getAssociatedTokenAddress } from '../../anchor';
import { findVaultSigner } from '../utils';
export function createEarnDepositInstr(program, vault, depositMint, iouMint, owner, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const [vaultSigner] = yield findVaultSigner(vault);
        const depositHolder = yield getAssociatedTokenAddress(vaultSigner, depositMint);
        const userDepositHolder = yield getAssociatedTokenAddress(owner, depositMint);
        const userIouHolder = yield getAssociatedTokenAddress(owner, iouMint);
        return program.methods
            .deposit(amount)
            .accounts({
            vault,
            vaultSigner,
            iouMint,
            depositHolder,
            userDepositHolder,
            userIouHolder,
            owner,
            tokenProgram: TOKEN_PROGRAM_ID
        })
            .instruction();
    });
}
export function createEarnWithdrawInstr(program, vault, depositMint, iouMint, owner, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const [vaultSigner] = yield findVaultSigner(vault);
        const depositHolder = yield getAssociatedTokenAddress(vaultSigner, depositMint);
        const userDepositHolder = yield getAssociatedTokenAddress(owner, depositMint);
        const userIouHolder = yield getAssociatedTokenAddress(owner, iouMint);
        return program.methods
            .withdraw(amount)
            .accounts({
            vault,
            vaultSigner,
            iouMint,
            depositHolder,
            userDepositHolder,
            userIouHolder,
            owner,
            tokenProgram: TOKEN_PROGRAM_ID
        })
            .instruction();
    });
}
