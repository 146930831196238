var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { SystemProgram, SYSVAR_CLOCK_PUBKEY, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { getAssociatedTokenAddress } from '../../anchor';
import { findVestConfigSigner, findVestingAddress, findVestingSigner } from '../utils';
export function createVestingStakeInstr(program, vestConfig, claimableMint, vestMint, userVestHolder, owner, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const [vestConfigSigner] = yield findVestConfigSigner(program.programId, vestConfig);
        const claimableHolder = yield getAssociatedTokenAddress(vestConfigSigner, claimableMint);
        const userClaimableHolder = yield getAssociatedTokenAddress(owner, claimableMint);
        return program.instruction.stake(amount, {
            accounts: {
                vestConfig,
                vestConfigSigner,
                vestMint,
                claimableHolder,
                userClaimableHolder,
                userVestHolder,
                owner,
                tokenProgram: TOKEN_PROGRAM_ID
            }
        });
    });
}
export function createVestingUpdateInstr(program, vestConfig, vestMint, owner) {
    return __awaiter(this, void 0, void 0, function* () {
        const vesting = yield findVestingAddress(program.programId, vestConfig, owner);
        const [vestingSigner] = yield findVestingSigner(program.programId, vesting);
        const vestedHolder = yield getAssociatedTokenAddress(vestingSigner, vestMint);
        return program.instruction.update({
            accounts: {
                vestConfig,
                vesting,
                vestedHolder,
                vestMint,
                vestingSigner,
                owner,
                clock: SYSVAR_CLOCK_PUBKEY,
                tokenProgram: TOKEN_PROGRAM_ID
            }
        });
    });
}
export function createInitVestingInstr(program, vestConfig, vestMint, vesting, owner) {
    return __awaiter(this, void 0, void 0, function* () {
        const [vestingSigner, nonce] = yield findVestingSigner(program.programId, vesting);
        const vestedHolder = yield getAssociatedTokenAddress(vestingSigner, vestMint);
        return program.instruction.initVesting(nonce, {
            accounts: {
                vestConfig,
                vesting,
                vestMint,
                vestedHolder,
                vestingSigner,
                payer: owner,
                rent: SYSVAR_RENT_PUBKEY,
                clock: SYSVAR_CLOCK_PUBKEY,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID
            }
        });
    });
}
export function createVestingVestInstr(program, vestConfig, vestMint, vesting, owner, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const updateInstr = yield createVestingUpdateInstr(program, vestConfig, vestMint, owner);
        const [vestingSigner] = yield findVestingSigner(program.programId, vesting);
        const vestedHolder = yield getAssociatedTokenAddress(vestingSigner, vestMint);
        const userVestHolder = yield getAssociatedTokenAddress(owner, vestMint);
        const vestInstr = program.instruction.vest(amount, {
            accounts: {
                vesting,
                vestedHolder,
                userVestHolder,
                owner,
                clock: SYSVAR_CLOCK_PUBKEY,
                tokenProgram: TOKEN_PROGRAM_ID
            },
            instructions: [updateInstr]
        });
        return [updateInstr, vestInstr];
    });
}
export function createVestingUnvestInstr(program, vestConfig, vestMint, owner, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        const updateInstr = yield createVestingUpdateInstr(program, vestConfig, vestMint, owner);
        const vesting = yield findVestingAddress(program.programId, vestConfig, owner);
        const [vestingSigner] = yield findVestingSigner(program.programId, vesting);
        const vestedHolder = yield getAssociatedTokenAddress(vestingSigner, vestMint);
        const userVestHolder = yield getAssociatedTokenAddress(owner, vestMint);
        const unvestInstr = program.instruction.unvest(amount, {
            accounts: {
                vestedHolder,
                vesting,
                vestingSigner,
                userVestHolder,
                owner,
                clock: SYSVAR_CLOCK_PUBKEY,
                tokenProgram: TOKEN_PROGRAM_ID
            },
            instructions: [updateInstr]
        });
        return [updateInstr, unvestInstr];
    });
}
export function createVestingClaimInstr(program, vestConfig, vestMint, claimableMint, owner) {
    return __awaiter(this, void 0, void 0, function* () {
        const updateInstr = yield createVestingUpdateInstr(program, vestConfig, vestMint, owner);
        const vesting = yield findVestingAddress(program.programId, vestConfig, owner);
        const userClaimableHolder = yield getAssociatedTokenAddress(owner, claimableMint);
        const [vestConfigSigner] = yield findVestConfigSigner(program.programId, vestConfig);
        const claimableHolder = yield getAssociatedTokenAddress(vestConfigSigner, claimableMint);
        const claimInstr = program.instruction.claim({
            accounts: {
                vestConfig,
                vestConfigSigner,
                claimableHolder,
                vesting,
                userClaimableHolder,
                owner,
                clock: SYSVAR_CLOCK_PUBKEY,
                tokenProgram: TOKEN_PROGRAM_ID
            },
            instructions: [updateInstr]
        });
        return [updateInstr, claimInstr];
    });
}
