import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _class_call_check from "@swc/helpers/src/_class_call_check.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { StakeAccountStatus, TOKENS } from "@parrot/programs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Keypair } from "@solana/web3.js";
import { apiClient } from "utils/apiClient";
import { sendZAPTransaction } from "utils/zap";
export var StakingAccount = function StakingAccount() {
    "use strict";
    _class_call_check(this, StakingAccount);
};
var initialState = {
    stakingPools: [],
    stakingAccounts: []
};
export var fetchStakingPools = createAsyncThunk("staking/pools", _async_to_generator(regeneratorRuntime.mark(function _callee() {
    var data;
    return regeneratorRuntime.wrap(function _callee$(_ctx) {
        while(1)switch(_ctx.prev = _ctx.next){
            case 0:
                _ctx.next = 2;
                return apiClient.get("/stakepool/parrot");
            case 2:
                data = _ctx.sent.data;
                return _ctx.abrupt("return", data);
            case 4:
            case "end":
                return _ctx.stop();
        }
    }, _callee);
})));
export var fetchStakingAccounts = createAsyncThunk("staking/accounts", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(ownerAddress) {
        var data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return apiClient.get("/stakes/".concat(ownerAddress));
                case 2:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(ownerAddress) {
        return _ref.apply(this, arguments);
    };
}());
var stakingSlice = createSlice({
    name: "staking",
    initialState: initialState,
    reducers: {
        clearStakingAccounts: function(state) {
            state.stakingAccounts = [];
        }
    },
    extraReducers: function(builder) {
        builder.addCase(fetchStakingPools.fulfilled, function(state, action) {
            state.stakingPools = action.payload.map(function(pool) {
                return _object_spread_props(_object_spread({}, pool), {
                    tokenInfo: TOKENS[pool.token]
                });
            });
        });
        builder.addCase(fetchStakingAccounts.fulfilled, function(state, action) {
            var statusSorting = [
                StakeAccountStatus.Active,
                StakeAccountStatus.Activating,
                StakeAccountStatus.Syncing,
                StakeAccountStatus.Deactivating,
                StakeAccountStatus.Deactivated,
                StakeAccountStatus.Inactive,
                StakeAccountStatus.Unsupported
            ].reverse();
            action.payload.sort(function(a, b) {
                return +b.amountFloat - +a.amountFloat;
            });
            action.payload.sort(function(a, b) {
                return statusSorting.indexOf(b.status) - statusSorting.indexOf(a.status);
            });
            state.stakingAccounts = action.payload;
        });
    }
});
// Actions
export var stakingReducer = stakingSlice.reducer, clearStakingAccounts = stakingSlice.actions.clearStakingAccounts;
// ZAP Instructions
export var actionDepositSOL = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(provider, amount) {
        var fundingAccount, accounts;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    fundingAccount = Keypair.generate();
                    accounts = {
                        payer: provider.wallet.publicKey.toBase58(),
                        ownerAccount: provider.wallet.publicKey.toBase58(),
                        fundingAccount: fundingAccount.publicKey.toBase58()
                    };
                    return _ctx.abrupt("return", sendZAPTransaction(provider, "prtsol", "DepositSOL", accounts, {
                        amount: amount
                    }, [
                        fundingAccount
                    ]));
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function actionDepositSOL(provider, amount) {
        return _ref.apply(this, arguments);
    };
}();
export var actionDepositStake = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(provider, stakeAccount, validatorAccount, amount) {
        var splitStakingAccount, accounts;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    splitStakingAccount = Keypair.generate();
                    accounts = {
                        ownerAccount: provider.wallet.publicKey.toBase58(),
                        userStakingAccount: stakeAccount.toBase58(),
                        userStakingValidator: validatorAccount.toBase58(),
                        splitStakingAccount: splitStakingAccount.publicKey.toBase58()
                    };
                    return _ctx.abrupt("return", sendZAPTransaction(provider, "prtsol", "DepositStake", accounts, {
                        amount: amount
                    }, [
                        splitStakingAccount
                    ]));
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function actionDepositStake(provider, stakeAccount, validatorAccount, amount) {
        return _ref.apply(this, arguments);
    };
}();
export var actionWithdrawStake = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(provider, amount) {
        var keypairs, accounts;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    keypairs = new Array(10).fill(0).map(function() {
                        return Keypair.generate();
                    });
                    accounts = {
                        payer: provider.wallet.publicKey.toBase58(),
                        ownerAccount: provider.wallet.publicKey.toBase58(),
                        keypairAccounts: keypairs.map(function(i) {
                            return i.publicKey.toBase58();
                        })
                    };
                    return _ctx.abrupt("return", sendZAPTransaction(provider, "prtsol", "WithdrawStake", accounts, {
                        amount: amount
                    }, keypairs));
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function actionWithdrawStake(provider, amount) {
        return _ref.apply(this, arguments);
    };
}();
