import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getDebtWithPrediction, INFINITY, PRT_VAULTS_CONFIG, STABLES_ORACLE, TOKENS, ZERO } from "@parrot/programs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PublicKey } from "@solana/web3.js";
import BigNumber from "bignumber.js";
import { CHANNEL, DEPLOYMENT } from "config/constants";
import { apiClient } from "utils/apiClient";
var SLOT_IN_5MINS = 600;
var SORTING = [
    "8PcJ5FmtmuYQCvBhaHkVY5DKVBn8BsMtV5RVqHU4h8ir",
    "D8HNnthcqpUbd81p2kL9KRXTWBKa82xgdFQTuNCEtQPX",
    "6BZVXRFut2HWLSGMEZRPsm2giRBDxpqSLhFNUa45jb7a",
    "2pHHpffMpLaLGc522EmV5w8T9BvHJwrDjpQEoa8fiU8Z",
    "2xjZS6GzCN3UAQQMxe57eaWt7bkAYN6yzNDagEsviLBH",
    "2chxdDkAYXuhcosfasR6sMDMhZkHUW28ngmw5dnojufd",
    "5JXNBn3iGBhdShhiVwe5JAxjARjsAodoVLhGipUuvJNA",
    "97TMdqkNUakyuQxSaNgWDsmv78YdG8Ja6msMsv9pVysY",
    "6DwQgD17xYSos3dH8PUpKYPxd3VdUS98UfaCp5X8v8mT",
    "5EBvQnroNCpAsnaXo3yZaM7ZM2uGz8rw9g9YmyhMtPWn",
    "593rnLeS2jxBLgd1iPvh8Ts7Ary4FK6S39x6Y7PHyzkV",
    "8T5J6jvVjXXkiNF8ouEDAwKqKJGDjqwZUwAnanm8ZnxW",
    "6vEUQp6Ym8WUW66rT9vPA7fw4H76Xhzw78QXJcofQMcw",
    "HLReWYQqKQogvGfv2GV8dH9LuoND7MVk7dqK1HsS61Ep",
    "7EPLnVPU5EG49AdMveEAsRj7MfkCWtQNmfgSG5zbdaSx",
    "AzFdKFiYqfDPi44mmuN5A1df8kjrTtkKLSnE3HJ9etR1",
    "71gB7NQ4zhgRF1hkRDR3PhmmSnzwKgY6QWZQ4JVTQwv4"
].reverse();
var initialVaults = PRT_VAULTS_CONFIG.map(function(cfg) {
    if (cfg.deployment !== DEPLOYMENT) {
        return;
    }
    var debtToken = TOKENS[cfg.debtType.state.debtToken];
    var collateralToken = TOKENS[cfg.state.collateralToken];
    if (!collateralToken || !debtToken) {
        return;
    }
    if (CHANNEL === "prod" && cfg.deployment !== "production") {
        return;
    }
    var isStable = cfg.state.priceOracle === STABLES_ORACLE.toBase58();
    var vault = {
        id: cfg.address,
        name: cfg.earn ? "".concat(cfg.name, "+Earn") : cfg.name,
        debtToken: debtToken,
        debtType: {
            publicKey: new PublicKey(cfg.debtType.address),
            debtToken: new PublicKey(cfg.debtType.state.debtToken)
        },
        collateralToken: collateralToken,
        vaultType: {
            publicKey: new PublicKey(cfg.address),
            collateralToken: new PublicKey(cfg.state.collateralToken),
            collateralTokenPrice: {},
            liquidationCollateralRatio: ZERO,
            minimumCollateralRatio: ZERO,
            debtLimit: ZERO,
            debtMinted: ZERO,
            debtCeiling: ZERO,
            debtOriginatorAmount: ZERO
        },
        userData: {
            debtAmount: ZERO,
            estimateDebtAmount: ZERO,
            collateralAmount: ZERO,
            interestAccum: ZERO
        },
        uiData: {
            isStable: isStable,
            isDeprecated: cfg.deprecated,
            isEarn: cfg.earn,
            maxDebtAdust: new BigNumber(isStable ? 0.9999 : 0.97),
            maxDebtLimit: INFINITY,
            maxDeposit: INFINITY
        }
    };
    return vault;
}).filter(function(vault) {
    return !!vault;
});
var initialState = {
    vaultsSynced: false,
    vaults: sortVaults(initialVaults)
};
export var fetchVaultTypes = createAsyncThunk("vaults/types", _async_to_generator(regeneratorRuntime.mark(function _callee() {
    var data;
    return regeneratorRuntime.wrap(function _callee$(_ctx) {
        while(1)switch(_ctx.prev = _ctx.next){
            case 0:
                _ctx.next = 2;
                return apiClient.get("/vaults/types");
            case 2:
                data = _ctx.sent.data;
                return _ctx.abrupt("return", data);
            case 4:
            case "end":
                return _ctx.stop();
        }
    }, _callee);
})));
export var fetchOwnerVaults = createAsyncThunk("vaults/owner/all", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(ownerAddress) {
        var data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return apiClient.get("/vaults/".concat(ownerAddress));
                case 2:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(ownerAddress) {
        return _ref.apply(this, arguments);
    };
}());
export var fetchOwnerVault = createAsyncThunk("vaults/owner/one", function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var ownerAddress, vaultAddress, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ownerAddress = param.ownerAddress, vaultAddress = param.vaultAddress;
                    _ctx.next = 3;
                    return apiClient.get("/vaults/".concat(ownerAddress, "/").concat(vaultAddress));
                case 3:
                    data = _ctx.sent.data;
                    return _ctx.abrupt("return", data);
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function(_) {
        return _ref.apply(this, arguments);
    };
}());
function sortVaults(vaults) {
    vaults.sort(function(b, a) {
        return SORTING.indexOf(a.id) - SORTING.indexOf(b.id);
    });
    return vaults.sort(function(b, a) {
        return +b.uiData.isDeprecated - +a.uiData.isDeprecated;
    });
}
function formatVaultType(vaultType) {
    return {
        publicKey: new PublicKey(vaultType.address),
        collateralToken: new PublicKey(vaultType.collateralToken),
        collateralTokenPrice: vaultType.collateralTokenPrice,
        interestRate: new BigNumber(vaultType.interestRate).multipliedBy(100),
        minimumCollateralRatio: new BigNumber(vaultType.minimumCollateralRatio),
        liquidationCollateralRatio: new BigNumber(vaultType.liquidationCollateralRatio),
        debtLimit: new BigNumber(vaultType.debtLimit),
        debtMinted: new BigNumber(vaultType.debtMinted),
        debtCeiling: new BigNumber(vaultType.debtCeiling),
        debtOriginatorAmount: new BigNumber(vaultType.debtOriginatorAmount),
        earnInfo: vaultType.earnInfo
    };
}
function formatVaultUserData(userData) {
    return {
        publicKey: (userData === null || userData === void 0 ? void 0 : userData.address) ? new PublicKey(userData.address) : undefined,
        debtAmount: new BigNumber((userData === null || userData === void 0 ? void 0 : userData.debtAmount) || "0"),
        estimateDebtAmount: userData ? new BigNumber(getDebtWithPrediction(userData.debtAmount, SLOT_IN_5MINS, userData.vaultTypeInfo.interestRate)) : ZERO,
        collateralAmount: new BigNumber((userData === null || userData === void 0 ? void 0 : userData.collateralAmount) || "0"),
        interestAccum: new BigNumber((userData === null || userData === void 0 ? void 0 : userData.interestAccum) || "0")
    };
}
var vaultSlice = createSlice({
    name: "vault",
    initialState: initialState,
    reducers: {
        openVault: function() {
        // TODO: for next UI iteration we might need this
        },
        clearOwnerVaults: function(state) {
            state.vaults = state.vaults.map(function(vault) {
                return _object_spread_props(_object_spread({}, vault), {
                    userData: formatVaultUserData(undefined)
                });
            });
        }
    },
    extraReducers: function(builder) {
        builder.addCase(fetchVaultTypes.fulfilled, function(state, action) {
            state.vaults = state.vaults.map(function(vault) {
                var vaultType = action.payload.find(function(i) {
                    return i.address === vault.vaultType.publicKey.toBase58();
                });
                if (vaultType) {
                    return _object_spread_props(_object_spread({}, vault), {
                        debtType: {
                            publicKey: new PublicKey(vaultType.debtTypeInfo.address),
                            debtToken: new PublicKey(vaultType.debtTypeInfo.debtToken)
                        },
                        vaultType: formatVaultType(vaultType),
                        uiData: _object_spread_props(_object_spread({}, vault.uiData), {
                            isDeprecated: vaultType.debtCeiling === "0"
                        })
                    });
                }
                return vault;
            });
            state.vaultsSynced = true;
        });
        builder.addCase(fetchOwnerVault.fulfilled, function(state, action) {
            state.vaults = state.vaults.map(function(vault) {
                var userData = action.payload;
                if (vault.id === userData.vaultType) {
                    return _object_spread_props(_object_spread({}, vault), {
                        userData: formatVaultUserData(userData),
                        vaultType: formatVaultType(userData.vaultTypeInfo),
                        uiData: _object_spread_props(_object_spread({}, vault.uiData), {
                            isDeprecated: userData.vaultTypeInfo.debtCeiling === "0"
                        })
                    });
                }
                return vault;
            });
        });
        builder.addCase(fetchOwnerVaults.fulfilled, function(state, action) {
            state.vaults = state.vaults.map(function(vault) {
                var userData = action.payload.find(function(i) {
                    return i.vaultType === vault.vaultType.publicKey.toBase58();
                });
                return _object_spread_props(_object_spread({}, vault), {
                    userData: formatVaultUserData(userData)
                });
            });
        });
    }
});
var _actions = vaultSlice.actions;
// Actions
export var openVault = _actions.openVault, clearOwnerVaults = _actions.clearOwnerVaults, vaultReducer = vaultSlice.reducer;
