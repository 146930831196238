import { AnchorProvider } from "@project-serum/anchor";
import { useConnectedWallet, useSolana } from "@saberhq/use-solana";
import { useMemo } from "react";
export var useWalletProvider = function() {
    var wallet = useConnectedWallet();
    var ref = useSolana(), connection = ref.connection, connected = ref.connected, network = ref.network, disconnect = ref.disconnect;
    var provider = useMemo(function() {
        if (wallet) {
            return new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions());
        }
    }, [
        wallet,
        connection
    ]);
    var walletAddress = connected ? wallet === null || wallet === void 0 ? void 0 : wallet.publicKey.toBase58() : undefined;
    return {
        connection: connection,
        wallet: wallet,
        provider: provider,
        connected: connected,
        disconnect: disconnect,
        walletNetwork: network,
        walletAddress: walletAddress
    };
};
