export var StakeAccountStatus;
(function (StakeAccountStatus) {
    StakeAccountStatus["Inactive"] = "Inactive";
    StakeAccountStatus["Active"] = "Active";
    StakeAccountStatus["Activating"] = "Activating";
    StakeAccountStatus["Deactivating"] = "Deactivating";
    StakeAccountStatus["Deactivated"] = "Deactivated";
    StakeAccountStatus["Syncing"] = "Syncing";
    StakeAccountStatus["Unsupported"] = "Unsupported";
})(StakeAccountStatus || (StakeAccountStatus = {}));
