var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { web3 } from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { SystemProgram, SYSVAR_CLOCK_PUBKEY, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { ensureATAInstr } from '../../anchor';
import { getVaultTypeConfig } from '../../constants/vaults';
export const createInitializeDebtTypeInstrs = ({ provider, program }, { debtType, debtToken, owner, debtOriginatorAuthority, nonce }) => __awaiter(void 0, void 0, void 0, function* () {
    const size = 512;
    const lamports = yield provider.connection.getMinimumBalanceForRentExemption(size);
    const { instructions: debtOriginatorPrepare, extra: { tokenAccount: debtOriginator } } = yield ensureATAInstr(provider, {
        tokenMint: debtToken,
        owner: debtOriginatorAuthority
    });
    const { instructions: interestsHolderPrepare, extra: { tokenAccount: interestsHolder } } = yield ensureATAInstr(provider, {
        tokenMint: debtToken,
        owner: provider.wallet.publicKey
    });
    return {
        instructions: [
            SystemProgram.createAccount({
                fromPubkey: owner,
                newAccountPubkey: debtType,
                lamports,
                space: size,
                programId: program.programId
            }),
            ...debtOriginatorPrepare,
            ...interestsHolderPrepare,
            yield program.methods
                .initDebtType(nonce)
                .accounts({
                debtType,
                debtToken,
                debtOriginator,
                interestsHolder,
                owner,
                rent: SYSVAR_RENT_PUBKEY
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createInitializeVaultTypeInstrs = ({ program, provider }, { args, accounts }) => __awaiter(void 0, void 0, void 0, function* () {
    const owner = provider.wallet.publicKey;
    const size = 1024;
    const lamports = yield provider.connection.getMinimumBalanceForRentExemption(size);
    const { instructions: collateralTokenHolderPrepare, extra: { tokenAccount: collateralTokenHolder } } = yield ensureATAInstr(provider, {
        tokenMint: accounts.collateralToken,
        owner: accounts.collateralTokenHolderAuthority
    });
    return {
        instructions: [
            SystemProgram.createAccount({
                fromPubkey: owner,
                newAccountPubkey: accounts.vaultType,
                lamports,
                space: size,
                programId: program.programId
            }),
            ...collateralTokenHolderPrepare,
            yield program.methods
                .initVaultType(...args)
                .accounts(Object.assign(Object.assign({}, accounts), { collateralTokenHolder, rent: SYSVAR_RENT_PUBKEY, clock: SYSVAR_CLOCK_PUBKEY }))
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createInitializeVaultInstrs = ({ program }, { vault, vaultType, owner }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        instructions: [
            yield program.methods
                .initVault()
                .accounts({
                vaultType,
                vault,
                owner,
                rent: web3.SYSVAR_RENT_PUBKEY,
                clock: web3.SYSVAR_CLOCK_PUBKEY,
                systemProgram: web3.SystemProgram.programId
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createStakeInstrs = ({ program }, { vaultType, vault, collateralFrom, collateralFromAuthority, collateralAmount }) => __awaiter(void 0, void 0, void 0, function* () {
    const vaultTypeConfig = getVaultTypeConfig(vaultType);
    return {
        instructions: [
            yield program.methods
                .stake(collateralAmount)
                .accounts({
                vaultType,
                vault,
                collateralFrom,
                collateralFromAuthority,
                collateralTo: vaultTypeConfig.collateralTokenHolder,
                tokenProgram: TOKEN_PROGRAM_ID,
                clock: web3.SYSVAR_CLOCK_PUBKEY
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createUnstakeInstrs = ({ program }, { vaultType, vault, vaultOwner, receiver, unstakeAmount }) => __awaiter(void 0, void 0, void 0, function* () {
    const vaultTypeConfig = getVaultTypeConfig(vaultType);
    return {
        instructions: [
            yield program.methods
                .unstake(unstakeAmount)
                .accounts({
                debtType: vaultTypeConfig.debtTypeAddress,
                vaultType,
                vault,
                oracle: vaultTypeConfig.priceOracle,
                vaultOwner,
                receiver,
                debtToken: vaultTypeConfig.debtTokenAddress,
                collateralToken: vaultTypeConfig.collateralTokenAddress,
                collateralTokenHolder: vaultTypeConfig.collateralTokenHolder,
                collateralTokenHolderAuthority: vaultTypeConfig.collateralTokenHolderAuthority,
                tokenProgram: TOKEN_PROGRAM_ID,
                clock: web3.SYSVAR_CLOCK_PUBKEY
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createBorrowInstrs = ({ program }, { vaultType, vault, vaultOwner, receiver, debtAmount }) => __awaiter(void 0, void 0, void 0, function* () {
    const vaultTypeConfig = getVaultTypeConfig(vaultType);
    return {
        instructions: [
            yield program.methods
                .borrow(debtAmount)
                .accounts({
                debtType: vaultTypeConfig.debtTypeAddress,
                vaultType,
                vault,
                oracle: vaultTypeConfig.priceOracle,
                vaultOwner,
                receiver,
                debtToken: vaultTypeConfig.debtTokenAddress,
                debtOriginator: vaultTypeConfig.debtOriginator,
                debtOriginatorAuthority: vaultTypeConfig.debtOriginatorAuthority,
                collateralTokenMint: vaultTypeConfig.collateralTokenAddress,
                tokenProgram: TOKEN_PROGRAM_ID,
                clock: web3.SYSVAR_CLOCK_PUBKEY
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createRepayInstrs = ({ program }, { vaultType, vault, debtFrom, debtFromAuthority, repayAmount }) => __awaiter(void 0, void 0, void 0, function* () {
    const vaultTypeConfig = getVaultTypeConfig(vaultType);
    return {
        instructions: [
            yield program.methods
                .repay(repayAmount)
                .accounts({
                debtType: vaultTypeConfig.debtTypeAddress,
                vaultType,
                vault,
                debtToken: vaultTypeConfig.debtTokenAddress,
                debtOriginator: vaultTypeConfig.debtOriginator,
                debtFrom,
                debtFromAuthority,
                tokenProgram: TOKEN_PROGRAM_ID,
                clock: web3.SYSVAR_CLOCK_PUBKEY
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
export const createLiquidateInstrs = ({ program }, { vaultType, vault, debtFrom, debtFromAuthority, liquidatedCollateralReceiver, liquidateAmount }) => __awaiter(void 0, void 0, void 0, function* () {
    const vaultTypeConfig = getVaultTypeConfig(vaultType);
    return {
        instructions: [
            yield program.methods
                .liquidate(liquidateAmount)
                .accounts({
                debtType: vaultTypeConfig.debtTypeAddress,
                vaultType,
                vault,
                oracle: vaultTypeConfig.priceOracle,
                tokenProgram: TOKEN_PROGRAM_ID,
                collateralToken: vaultTypeConfig.collateralTokenAddress,
                collateralTokenHolder: vaultTypeConfig.collateralTokenHolder,
                collateralTokenHolderAuthority: vaultTypeConfig.collateralTokenHolderAuthority,
                debtToken: vaultTypeConfig.debtTokenAddress,
                debtOriginator: vaultTypeConfig.debtOriginator,
                debtFrom,
                debtFromAuthority,
                liquidatedCollateralReceiver,
                clock: web3.SYSVAR_CLOCK_PUBKEY
            })
                .instruction()
        ],
        cleanupInstructions: [],
        signers: [],
        extra: {}
    };
});
