import tokens from './data/tokens/byAddress.go.json';
export const TOKENS = tokens;
export function getTokenInfo(mint) {
    if (typeof mint !== 'string') {
        mint = mint.toBase58();
    }
    const token = TOKENS[mint];
    if (!token) {
        throw new Error(`token not found for mint ${mint}`);
    }
    return {
        mint: token.address,
        symbol: token.symbol,
        decimals: token.decimals
    };
}
export function getTokenBySymbol(symbol) {
    return Object.values(TOKENS).find(t => t.symbol === symbol);
}
