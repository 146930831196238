var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { CONFIG_SEED_PREFIX, VESTING_SEED_PREFIX } from '../sdk/constants';
export function findVestConfigAddress(programId, claimableMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const [addr] = yield PublicKey.findProgramAddress([Buffer.from('vest_config'), claimableMint.toBuffer()], programId);
        return addr;
    });
}
export function findVestConfigSigner(programId, vestConfigAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield PublicKey.findProgramAddress([Buffer.from(CONFIG_SEED_PREFIX), vestConfigAddress.toBuffer()], programId);
    });
}
export function findVestingAddress(programId, vestConfigAddress, owner) {
    return __awaiter(this, void 0, void 0, function* () {
        const [addr] = yield PublicKey.findProgramAddress([Buffer.from('vesting'), vestConfigAddress.toBuffer(), owner.toBuffer()], programId);
        return addr;
    });
}
export function findVestingSigner(programId, vestingAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield PublicKey.findProgramAddress([Buffer.from(VESTING_SEED_PREFIX), vestingAddress.toBuffer()], programId);
    });
}
export function estimatedVestingClaimable(halfLifeDuration, //vestConfig.halfLifeDuration
claimAllDuration, //vestConfig.claimAllDuration
vestedHolderAmount, lastUpdatedTime, lastVestTime, claimableAmount, updateTime //in seconds
) {
    if (updateTime <= lastUpdatedTime) {
        throw Error('update time should gt lastUpdateTime');
    }
    //no more vested amount
    if (vestedHolderAmount <= 0) {
        return new BigNumber(claimableAmount);
    }
    // claimed all
    if (updateTime - lastVestTime > claimAllDuration) {
        return new BigNumber(claimableAmount).plus(vestedHolderAmount);
    }
    const timeElapsed = updateTime - lastUpdatedTime;
    const newRemainedAmount = vestedHolderAmount * Math.exp((-Math.LN2 * timeElapsed) / halfLifeDuration);
    const newClaimableAmount = vestedHolderAmount - newRemainedAmount;
    return new BigNumber(claimableAmount).plus(newClaimableAmount);
}
export function estimatedVestingUnlockPerDay(halfLifeDuration, //vestConfig.halfLifeDuration
claimAllDuration, //vestConfig.claimAllDuration
vestedHolderAmount, lastUpdatedTime, lastVestTime, claimableAmount, currentTime //in seconds
) {
    const currentState = estimatedVestingClaimable(halfLifeDuration, claimAllDuration, vestedHolderAmount, lastUpdatedTime, lastVestTime, claimableAmount, Math.floor(currentTime));
    const afterOneDay = estimatedVestingClaimable(halfLifeDuration, claimAllDuration, vestedHolderAmount, lastUpdatedTime, lastVestTime, claimableAmount, Math.floor(currentTime + 60 * 60 * 24));
    return new BigNumber(afterOneDay).minus(currentState);
}
