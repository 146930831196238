import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { shortenAddress } from "@parrot/programs";
import { createSlice } from "@reduxjs/toolkit";
import { getExplorerUrl } from "utils/getExplorerUrl";
var globalId = 0;
var nextId = function() {
    return "".concat(new Date().valueOf(), "_").concat(globalId++);
};
var initialState = {
    notifications: []
};
export var notificationSlice = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        notifyShow: function(state, action) {
            var txId = action.payload.txId;
            if (txId) {
                action.payload.action = {
                    text: "Check your transaction",
                    link: shortenAddress(txId),
                    url: getExplorerUrl("tx", txId, "")
                };
            }
            state.notifications = _to_consumable_array(state.notifications).concat([
                _object_spread_props(_object_spread({}, action.payload), {
                    id: nextId()
                })
            ]);
        },
        notifyRemove: function(state, action) {
            state.notifications = state.notifications.filter(function(i) {
                return i.id !== action.payload;
            });
        }
    }
});
// Actions
var _actions = notificationSlice.actions, notifyShow = _actions.notifyShow, notifyRemove = _actions.notifyRemove, notificationReducer = notificationSlice.reducer;
export { notificationReducer, notifyRemove, notifyShow };
