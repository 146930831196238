export const AnchorParrotIDL = {
    version: '0.1.0',
    name: 'parrot',
    instructions: [
        {
            name: 'initDebtType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtTypePda',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'interestsHolder',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'associatedTokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'nonce',
                    type: 'u8'
                }
            ]
        },
        {
            name: 'updateDebtType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                }
            ],
            args: [
                {
                    name: 'update',
                    type: {
                        defined: 'DebtTypeUpdate'
                    }
                }
            ]
        },
        {
            name: 'burnFromDebtOriginator',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtOriginatorAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'burnAmount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'initVaultType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'payer',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'priceOracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'nonce',
                    type: 'u8'
                },
                {
                    name: 'minimumCollateralRatio',
                    type: 'u64'
                },
                {
                    name: 'liquidationCollateralRatio',
                    type: 'u64'
                },
                {
                    name: 'liquidationPenalty',
                    type: 'u64'
                },
                {
                    name: 'interestRate',
                    type: 'u128'
                },
                {
                    name: 'debtCeiling',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'updateVaultType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'update',
                    type: {
                        defined: 'VaultTypeUpdate'
                    }
                }
            ]
        },
        {
            name: 'investFromVaultType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'receiver',
                    isMut: true,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'collateralAmount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'initVault',
            accounts: [
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        },
        {
            name: 'collectVaultInterests',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtOriginatorAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'interestsHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                }
            ],
            args: []
        },
        {
            name: 'stake',
            accounts: [
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'collateralTo',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'borrow',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenMint',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vaultOwner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtOriginatorAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'receiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'repay',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'unstake',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultOwner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'receiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'liquidate',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'liquidatedCollateralReceiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'clearBadDebt',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'liquidatedCollateralReceiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        },
        {
            name: 'initOracle',
            accounts: [
                {
                    name: 'oracle',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        },
        {
            name: 'setOracleValue',
            accounts: [
                {
                    name: 'oracle',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'price',
                    type: 'u64'
                },
                {
                    name: 'priceDecimal',
                    type: 'u8'
                }
            ]
        },
        {
            name: 'vaultDebtAccum',
            accounts: [
                {
                    name: 'vaultType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        }
    ],
    accounts: [
        {
            name: 'debtType',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'version',
                        type: 'u8'
                    },
                    {
                        name: 'debtToken',
                        docs: ['debt token'],
                        type: 'publicKey'
                    },
                    {
                        name: 'debtOriginator',
                        docs: ['debt token source account'],
                        type: 'publicKey'
                    },
                    {
                        name: 'interestsHolder',
                        docs: ['debt interest holder account'],
                        type: 'publicKey'
                    },
                    {
                        name: 'owner',
                        docs: ['debt token owner'],
                        type: 'publicKey'
                    },
                    {
                        name: 'nonce',
                        docs: ['signer nonce.'],
                        type: 'u8'
                    }
                ]
            }
        },
        {
            name: 'vaultType',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'version',
                        type: 'u8'
                    },
                    {
                        name: 'debtType',
                        docs: ['belongs to this debt type.'],
                        type: 'publicKey'
                    },
                    {
                        name: 'collateralToken',
                        docs: ['type of spl-token to accept as collateral.'],
                        type: 'publicKey'
                    },
                    {
                        name: 'collateralTokenHolder',
                        docs: [
                            'token account to hold the collaterals. A program account owns this token account.'
                        ],
                        type: 'publicKey'
                    },
                    {
                        name: 'priceOracle',
                        type: 'publicKey'
                    },
                    {
                        name: 'nonce',
                        docs: ['signer nonce.'],
                        type: 'u8'
                    },
                    {
                        name: 'minimumCollateralRatio',
                        docs: [
                            'limit the max amount of debt a user can generate with borrow.'
                        ],
                        type: 'u64'
                    },
                    {
                        name: 'liquidationCollateralRatio',
                        docs: ['controls when the liquidator can liquidate this vault'],
                        type: 'u64'
                    },
                    {
                        name: 'liquidationPenalty',
                        docs: ['reward for liquidator'],
                        type: 'u64'
                    },
                    {
                        name: 'interestRate',
                        type: 'u128'
                    },
                    {
                        name: 'interestAccum',
                        type: 'u128'
                    },
                    {
                        name: 'interestAccumUpdated',
                        type: 'u64'
                    },
                    {
                        name: 'accruedInterests',
                        type: 'u128'
                    },
                    {
                        name: 'debtCeiling',
                        type: 'u64'
                    },
                    {
                        name: 'totalDebt',
                        type: 'u128'
                    }
                ]
            }
        },
        {
            name: 'vault',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'version',
                        type: 'u8'
                    },
                    {
                        name: 'vaultType',
                        docs: ['belongs to this vault type'],
                        type: 'publicKey'
                    },
                    {
                        name: 'owner',
                        docs: ['owner of the vault (usually the user wallet)'],
                        type: 'publicKey'
                    },
                    {
                        name: 'debtAmount',
                        docs: ['user total debt amount'],
                        type: 'u128'
                    },
                    {
                        name: 'collateralAmount',
                        docs: ['user total collateral amount'],
                        type: 'u128'
                    },
                    {
                        name: 'interestAccum',
                        docs: [''],
                        type: 'u128'
                    },
                    {
                        name: 'nonce',
                        type: 'u8'
                    }
                ]
            }
        },
        {
            name: 'mockOracle',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'price',
                        type: 'u64'
                    },
                    {
                        name: 'priceDecimal',
                        type: 'u8'
                    }
                ]
            }
        }
    ],
    types: [
        {
            name: 'DebtTypeUpdate',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'owner',
                        type: {
                            option: 'publicKey'
                        }
                    },
                    {
                        name: 'interestsHolder',
                        type: {
                            option: 'publicKey'
                        }
                    }
                ]
            }
        },
        {
            name: 'VaultTypeUpdate',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'minimumCollateralRatio',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'liquidationCollateralRatio',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'liquidationPenalty',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'interestRate',
                        type: {
                            option: 'u128'
                        }
                    },
                    {
                        name: 'debtCeiling',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'priceOracle',
                        type: {
                            option: 'publicKey'
                        }
                    }
                ]
            }
        },
        {
            name: 'EventType',
            type: {
                kind: 'enum',
                variants: [
                    {
                        name: 'UpdateDebtType',
                        fields: [
                            {
                                name: 'debt_type',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'InitVaultType',
                        fields: [
                            {
                                name: 'vault_type',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'UpdateVaultType',
                        fields: [
                            {
                                name: 'vault_type',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'InitVault',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'Stake',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Borrow',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Repay',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Unstake',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Liquidate',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'VaultDebtAccum',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u128'
                            }
                        ]
                    },
                    {
                        name: 'Invest',
                        fields: [
                            {
                                name: 'vault_type',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'ClearBadDebt',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'debt',
                                type: 'u64'
                            },
                            {
                                name: 'collateral_paid',
                                type: 'u64'
                            },
                            {
                                name: 'collateral_cleared',
                                type: 'u64'
                            }
                        ]
                    }
                ]
            }
        }
    ],
    events: [
        {
            name: 'AnchorParrotEvt',
            fields: [
                {
                    name: 'version',
                    type: 'u8',
                    index: false
                },
                {
                    name: 'typ',
                    type: {
                        defined: 'EventType'
                    },
                    index: false
                }
            ]
        }
    ],
    errors: [
        {
            code: 6000,
            name: 'NumberOverflow',
            msg: '[P001] number overflow'
        },
        {
            code: 6001,
            name: 'InsufficientCollateral',
            msg: '[P002] Insufficient collateral ratio'
        },
        {
            code: 6002,
            name: 'LiquidationOverCollateralize',
            msg: '[P003] try to liquidate a vault that still have enough collateral'
        },
        {
            code: 6003,
            name: 'PriceOracleMismatch',
            msg: '[P004] invalid price oracle'
        },
        {
            code: 6004,
            name: 'InvalidParams',
            msg: '[P005] invalid params'
        },
        {
            code: 6005,
            name: 'ReachVaultTypeCeiling',
            msg: '[P006] reach vault type global mint ceiling'
        },
        {
            code: 6006,
            name: 'NotEnoughAccountSize',
            msg: '[P007] not enough account size allocated'
        },
        {
            code: 6007,
            name: 'InvalidStableLiquidation',
            msg: '[P008] cannot liquidate stable vaults'
        },
        {
            code: 6008,
            name: 'InvalidOraclePrice',
            msg: '[P009] invalid oracle price'
        },
        {
            code: 6009,
            name: 'InvalidAdmin',
            msg: '[P010] invalid admin'
        }
    ]
};
export const ParrotJSON = {
    version: '0.1.0',
    name: 'parrot',
    instructions: [
        {
            name: 'initDebtType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtTypePda',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'interestsHolder',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'associatedTokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'nonce',
                    type: 'u8'
                }
            ]
        },
        {
            name: 'updateDebtType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                }
            ],
            args: [
                {
                    name: 'update',
                    type: {
                        defined: 'DebtTypeUpdate'
                    }
                }
            ]
        },
        {
            name: 'burnFromDebtOriginator',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtOriginatorAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'burnAmount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'initVaultType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'payer',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'priceOracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'nonce',
                    type: 'u8'
                },
                {
                    name: 'minimumCollateralRatio',
                    type: 'u64'
                },
                {
                    name: 'liquidationCollateralRatio',
                    type: 'u64'
                },
                {
                    name: 'liquidationPenalty',
                    type: 'u64'
                },
                {
                    name: 'interestRate',
                    type: 'u128'
                },
                {
                    name: 'debtCeiling',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'updateVaultType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'update',
                    type: {
                        defined: 'VaultTypeUpdate'
                    }
                }
            ]
        },
        {
            name: 'investFromVaultType',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'receiver',
                    isMut: true,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'collateralAmount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'initVault',
            accounts: [
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: true,
                    isSigner: true
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        },
        {
            name: 'collectVaultInterests',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtOriginatorAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'interestsHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'owner',
                    isMut: false,
                    isSigner: true
                }
            ],
            args: []
        },
        {
            name: 'stake',
            accounts: [
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'collateralTo',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'borrow',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenMint',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vaultOwner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtOriginatorAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'receiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'repay',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'unstake',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultOwner',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'receiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'liquidate',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'liquidatedCollateralReceiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64'
                }
            ]
        },
        {
            name: 'clearBadDebt',
            accounts: [
                {
                    name: 'debtType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vaultType',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'oracle',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolder',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'collateralTokenHolderAuthority',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtToken',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'debtOriginator',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFrom',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'debtFromAuthority',
                    isMut: false,
                    isSigner: true
                },
                {
                    name: 'liquidatedCollateralReceiver',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        },
        {
            name: 'initOracle',
            accounts: [
                {
                    name: 'oracle',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'rent',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        },
        {
            name: 'setOracleValue',
            accounts: [
                {
                    name: 'oracle',
                    isMut: true,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: [
                {
                    name: 'price',
                    type: 'u64'
                },
                {
                    name: 'priceDecimal',
                    type: 'u8'
                }
            ]
        },
        {
            name: 'vaultDebtAccum',
            accounts: [
                {
                    name: 'vaultType',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'vault',
                    isMut: false,
                    isSigner: false
                },
                {
                    name: 'clock',
                    isMut: false,
                    isSigner: false
                }
            ],
            args: []
        }
    ],
    accounts: [
        {
            name: 'DebtType',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'version',
                        type: 'u8'
                    },
                    {
                        name: 'debtToken',
                        docs: ['debt token'],
                        type: 'publicKey'
                    },
                    {
                        name: 'debtOriginator',
                        docs: ['debt token source account'],
                        type: 'publicKey'
                    },
                    {
                        name: 'interestsHolder',
                        docs: ['debt interest holder account'],
                        type: 'publicKey'
                    },
                    {
                        name: 'owner',
                        docs: ['debt token owner'],
                        type: 'publicKey'
                    },
                    {
                        name: 'nonce',
                        docs: ['signer nonce.'],
                        type: 'u8'
                    }
                ]
            }
        },
        {
            name: 'VaultType',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'version',
                        type: 'u8'
                    },
                    {
                        name: 'debtType',
                        docs: ['belongs to this debt type.'],
                        type: 'publicKey'
                    },
                    {
                        name: 'collateralToken',
                        docs: ['type of spl-token to accept as collateral.'],
                        type: 'publicKey'
                    },
                    {
                        name: 'collateralTokenHolder',
                        docs: [
                            'token account to hold the collaterals. A program account owns this token account.'
                        ],
                        type: 'publicKey'
                    },
                    {
                        name: 'priceOracle',
                        type: 'publicKey'
                    },
                    {
                        name: 'nonce',
                        docs: ['signer nonce.'],
                        type: 'u8'
                    },
                    {
                        name: 'minimumCollateralRatio',
                        docs: [
                            'limit the max amount of debt a user can generate with borrow.'
                        ],
                        type: 'u64'
                    },
                    {
                        name: 'liquidationCollateralRatio',
                        docs: ['controls when the liquidator can liquidate this vault'],
                        type: 'u64'
                    },
                    {
                        name: 'liquidationPenalty',
                        docs: ['reward for liquidator'],
                        type: 'u64'
                    },
                    {
                        name: 'interestRate',
                        type: 'u128'
                    },
                    {
                        name: 'interestAccum',
                        type: 'u128'
                    },
                    {
                        name: 'interestAccumUpdated',
                        type: 'u64'
                    },
                    {
                        name: 'accruedInterests',
                        type: 'u128'
                    },
                    {
                        name: 'debtCeiling',
                        type: 'u64'
                    },
                    {
                        name: 'totalDebt',
                        type: 'u128'
                    }
                ]
            }
        },
        {
            name: 'Vault',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'version',
                        type: 'u8'
                    },
                    {
                        name: 'vaultType',
                        docs: ['belongs to this vault type'],
                        type: 'publicKey'
                    },
                    {
                        name: 'owner',
                        docs: ['owner of the vault (usually the user wallet)'],
                        type: 'publicKey'
                    },
                    {
                        name: 'debtAmount',
                        docs: ['user total debt amount'],
                        type: 'u128'
                    },
                    {
                        name: 'collateralAmount',
                        docs: ['user total collateral amount'],
                        type: 'u128'
                    },
                    {
                        name: 'interestAccum',
                        docs: [''],
                        type: 'u128'
                    },
                    {
                        name: 'nonce',
                        type: 'u8'
                    }
                ]
            }
        },
        {
            name: 'MockOracle',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'price',
                        type: 'u64'
                    },
                    {
                        name: 'priceDecimal',
                        type: 'u8'
                    }
                ]
            }
        }
    ],
    types: [
        {
            name: 'DebtTypeUpdate',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'owner',
                        type: {
                            option: 'publicKey'
                        }
                    },
                    {
                        name: 'interestsHolder',
                        type: {
                            option: 'publicKey'
                        }
                    }
                ]
            }
        },
        {
            name: 'VaultTypeUpdate',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'minimumCollateralRatio',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'liquidationCollateralRatio',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'liquidationPenalty',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'interestRate',
                        type: {
                            option: 'u128'
                        }
                    },
                    {
                        name: 'debtCeiling',
                        type: {
                            option: 'u64'
                        }
                    },
                    {
                        name: 'priceOracle',
                        type: {
                            option: 'publicKey'
                        }
                    }
                ]
            }
        },
        {
            name: 'EventType',
            type: {
                kind: 'enum',
                variants: [
                    {
                        name: 'UpdateDebtType',
                        fields: [
                            {
                                name: 'debt_type',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'InitVaultType',
                        fields: [
                            {
                                name: 'vault_type',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'UpdateVaultType',
                        fields: [
                            {
                                name: 'vault_type',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'InitVault',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            }
                        ]
                    },
                    {
                        name: 'Stake',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Borrow',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Repay',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Unstake',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'Liquidate',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'VaultDebtAccum',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u128'
                            }
                        ]
                    },
                    {
                        name: 'Invest',
                        fields: [
                            {
                                name: 'vault_type',
                                type: 'publicKey'
                            },
                            {
                                name: 'amount',
                                type: 'u64'
                            }
                        ]
                    },
                    {
                        name: 'ClearBadDebt',
                        fields: [
                            {
                                name: 'vault',
                                type: 'publicKey'
                            },
                            {
                                name: 'debt',
                                type: 'u64'
                            },
                            {
                                name: 'collateral_paid',
                                type: 'u64'
                            },
                            {
                                name: 'collateral_cleared',
                                type: 'u64'
                            }
                        ]
                    }
                ]
            }
        }
    ],
    events: [
        {
            name: 'ParrotEvt',
            fields: [
                {
                    name: 'version',
                    type: 'u8',
                    index: false
                },
                {
                    name: 'typ',
                    type: {
                        defined: 'EventType'
                    },
                    index: false
                }
            ]
        }
    ],
    errors: [
        {
            code: 6000,
            name: 'NumberOverflow',
            msg: '[P001] number overflow'
        },
        {
            code: 6001,
            name: 'InsufficientCollateral',
            msg: '[P002] Insufficient collateral ratio'
        },
        {
            code: 6002,
            name: 'LiquidationOverCollateralize',
            msg: '[P003] try to liquidate a vault that still have enough collateral'
        },
        {
            code: 6003,
            name: 'PriceOracleMismatch',
            msg: '[P004] invalid price oracle'
        },
        {
            code: 6004,
            name: 'InvalidParams',
            msg: '[P005] invalid params'
        },
        {
            code: 6005,
            name: 'ReachVaultTypeCeiling',
            msg: '[P006] reach vault type global mint ceiling'
        },
        {
            code: 6006,
            name: 'NotEnoughAccountSize',
            msg: '[P007] not enough account size allocated'
        },
        {
            code: 6007,
            name: 'InvalidStableLiquidation',
            msg: '[P008] cannot liquidate stable vaults'
        },
        {
            code: 6008,
            name: 'InvalidOraclePrice',
            msg: '[P009] invalid oracle price'
        },
        {
            code: 6009,
            name: 'InvalidAdmin',
            msg: '[P010] invalid admin'
        }
    ]
};
