import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/app/packages/lending/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { ZERO } from "@parrot/programs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { apiClient } from "utils/apiClient";
var initialState = {
    tvlError: false,
    tvlLoading: true,
    tvlAmount: ZERO
};
export var fetchStatsTvl = createAsyncThunk("stats/tvl", _async_to_generator(regeneratorRuntime.mark(function _callee() {
    var data;
    return regeneratorRuntime.wrap(function _callee$(_ctx) {
        while(1)switch(_ctx.prev = _ctx.next){
            case 0:
                _ctx.next = 2;
                return apiClient.get("/stats/tvl");
            case 2:
                data = _ctx.sent.data;
                return _ctx.abrupt("return", data);
            case 4:
            case "end":
                return _ctx.stop();
        }
    }, _callee);
})));
var statsSlice = createSlice({
    name: "stats",
    initialState: initialState,
    reducers: {},
    extraReducers: function(builder) {
        builder.addCase(fetchStatsTvl.pending, function(state) {
            if (state.tvlAmount.isZero()) {
                state.tvlLoading = true;
            }
        });
        builder.addCase(fetchStatsTvl.fulfilled, function(state, action) {
            state.tvlLoading = false;
            state.tvlAmount = new BigNumber(action.payload.tvlFloat);
            state.tvlError = state.tvlAmount.isZero();
        });
        builder.addCase(fetchStatsTvl.rejected, function(state) {
            state.tvlLoading = false;
            if (state.tvlAmount.isZero()) {
                state.tvlError = true;
            }
        });
    }
});
// Actions
export var statsReducer = statsSlice.reducer;
