var webSiteUrl = "https://parrot.fi/";
var webSiteDesc = "party parrot solana non-custodial lending defi and liquidity network";
var config = {
    titleTemplate: "%s | PartyParrot",
    description: webSiteDesc,
    canonical: webSiteUrl,
    openGraph: {
        type: "website",
        locale: "en_US",
        defaultImageWidth: 200,
        defaultImageHeight: 200,
        url: webSiteUrl,
        site_name: "PartyParrot",
        description: webSiteDesc,
        images: [
            {
                url: "".concat(webSiteUrl, "images/logo-share.png"),
                width: 200,
                height: 200
            }
        ]
    },
    twitter: {
        cardType: "summary",
        handle: "@gopartyparrot",
        site: "@gopartyparrot"
    }
};
export default config;
