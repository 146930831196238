import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { SOLANA_NETWORK } from "./constants";
var menus = [
    {
        id: "mint",
        title: "Mint",
        href: "/mint"
    }
].concat(_to_consumable_array(SOLANA_NETWORK === "devnet" ? [
    {
        id: "faucet",
        title: "Faucet",
        href: "/faucet"
    }
] : []), [
    {
        id: "vaults",
        title: "Vaults",
        href: "/vaults"
    },
    // {
    //   id: 'earn',
    //   title: 'Earn',
    //   href: '#',
    //   isGroup: true,
    //   children: [
    //     {
    //       id: 'stake-sol',
    //       title: 'Stake SOL',
    //       href: '/stake-sol'
    //     },
    //     {
    //       id: 'stake-prt',
    //       title: 'Stake PRT',
    //       href: '/yprt'
    //     },
    //     {
    //       id: 'stability',
    //       title: 'Stability pools',
    //       href: '/stability'
    //     }
    //   ]
    // },
    {
        id: "rewards",
        title: "Rewards",
        href: "https://app.png.fi/rewards",
        target: "parrot-rewards",
        isExternal: true
    },
    {
        id: "more",
        title: "More",
        href: "#",
        isGroup: true,
        children: [
            // {
            //   id: 'stats',
            //   title: 'Stats',
            //   href: 'https://stats2.parrot.fi',
            //   target: 'parrot-stats',
            //   isExternal: true
            // },
            {
                id: "guide",
                title: "Guide",
                href: "https://doc.parrot.fi/guide/",
                target: "parrot-doc",
                isExternal: true
            }
        ]
    }
]);
var socials = [
    {
        id: "twitter",
        title: "twitter",
        icon: "twitter-icon.svg",
        href: "https://twitter.com/gopartyparrot",
        target: "parrot-twitter"
    },
    {
        id: "medium",
        title: "medium",
        icon: "medium-icon.svg",
        href: "https://gopartyparrot.medium.com",
        target: "parrot-medium"
    },
    {
        id: "telegram",
        title: "telegram",
        icon: "telegram-icon.svg",
        href: "https://t.me/gopartyparrot",
        target: "parrot-telegram"
    },
    {
        id: "discord",
        title: "discord",
        icon: "discord-icon.svg",
        href: "https://discord.gg/gopartyparrot",
        target: "parrot-discord"
    }
];
export { menus, socials };
