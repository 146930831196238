var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { web3 } from '@project-serum/anchor';
import { AccountLayout, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { WSOL_MINT_ADDRESS } from '../utils';
// TODO discuss to use ata of wsol
export const createTempWSOLAccountInstrs = (provider, { lamports }) => __awaiter(void 0, void 0, void 0, function* () {
    const tokenAccount = new web3.Keypair();
    const accountRentExempt = yield provider.connection.getMinimumBalanceForRentExemption(AccountLayout.span);
    const createAccountInst = web3.SystemProgram.createAccount({
        fromPubkey: provider.wallet.publicKey,
        newAccountPubkey: tokenAccount.publicKey,
        lamports: lamports + accountRentExempt,
        space: AccountLayout.span,
        programId: TOKEN_PROGRAM_ID
    });
    const createInitAccountInst = Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, WSOL_MINT_ADDRESS, tokenAccount.publicKey, provider.wallet.publicKey);
    const closeAccountInstruction = Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, tokenAccount.publicKey, provider.wallet.publicKey, provider.wallet.publicKey, []);
    return {
        instructions: [createAccountInst, createInitAccountInst],
        cleanupInstructions: [closeAccountInstruction],
        signers: [tokenAccount],
        extra: {
            tokenAccount
        }
    };
});
