var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Program, web3 } from '@project-serum/anchor';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import BN from 'bn.js';
import { createAssociatedTokenAccountInstrs } from '../../anchor';
import { getFaucetConfig } from '../config';
export class FaucetProgram {
    constructor(provider, config) {
        this.provider = provider;
        this.faucetConfig = config !== null && config !== void 0 ? config : getFaucetConfig();
        this.program = new Program(this.faucetConfig.programIdl, new web3.PublicKey(this.faucetConfig.programId), provider);
    }
    get programId() {
        return this.program.programId;
    }
    get faucets() {
        return this.faucetConfig.faucets.map(faucet => ({
            faucetConfig: new web3.PublicKey(faucet.faucet_config),
            tokenMint: new web3.PublicKey(faucet.token_mint)
        }));
    }
    debug() {
        return __awaiter(this, void 0, void 0, function* () {
            const all = yield this.program.account.faucetConfig.all();
            all.forEach(i => {
                console.log('i.publicKey', i.publicKey.toBase58());
                console.log('i.tokenMint', i.account);
            });
        });
    }
    airdrop(tokenMint, amount, { receiver }) {
        return __awaiter(this, void 0, void 0, function* () {
            const faucet = this.faucets.find(f => f.tokenMint.toBase58() === tokenMint);
            if (!faucet) {
                throw Error(`Token: ${tokenMint} is not supported`);
            }
            const signers = [];
            const instructions = [];
            const { value: tokenAccounts } = yield this.provider.connection.getTokenAccountsByOwner(receiver, { mint: faucet.tokenMint }, 'processed');
            let receiverTokenAccountPk = undefined;
            if (tokenAccounts.length > 0) {
                receiverTokenAccountPk = tokenAccounts[0].pubkey;
            }
            if (tokenAccounts.length === 0) {
                const receiverTokenAccount = new web3.Account();
                receiverTokenAccountPk = receiverTokenAccount.publicKey;
                instructions.push(...(yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: faucet.tokenMint,
                    owner: receiver
                })).instructions);
                signers.push(receiverTokenAccount);
            }
            const token = new Token(this.provider.connection, faucet.tokenMint, TOKEN_PROGRAM_ID, this.provider.wallet.payer);
            const tokenMintInfo = yield token.getMintInfo();
            let method = this.program.methods.airdrop(new BN(amount)).accounts({
                faucetConfig: faucet.faucetConfig,
                receiver: receiverTokenAccountPk,
                tokenProgram: TOKEN_PROGRAM_ID,
                tokenMint: faucet.tokenMint,
                tokenAuthority: tokenMintInfo.mintAuthority
            });
            if (instructions.length > 0) {
                method = method.preInstructions(instructions);
            }
            if (signers.length > 0) {
                method = method.signers(signers);
            }
            return method.rpc();
        });
    }
}
