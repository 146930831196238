import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { ZERO } from "@parrot/programs";
import { useCallback, useEffect, useState } from "react";
import { fetchBalance, useAppDispatch, useAppSelector } from "state";
import useInterval from "./useInterval";
import { useToast } from "./useToast";
import { useWalletProvider } from "./useWalletProvider";
/**
 * Provide function to fetch a Token Account balance
 * @param options if usePolling is true, start a pooling balance every 15 seconds
 * @param deps extra deps for refetch balances
 */ export var useBalance = function(_param) {
    var deps = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    var token = _param.token, _usePolling = _param.usePolling, usePolling = _usePolling === void 0 ? false : _usePolling, _skipUpdates = _param.skipUpdates, skipUpdates = _skipUpdates === void 0 ? false : _skipUpdates, options = _object_without_properties(_param, [
        "token",
        "usePolling",
        "skipUpdates"
    ]);
    var dispatch = useAppDispatch();
    var balances = useAppSelector(function(state) {
        return state.balance.balances;
    });
    var walletAddress = useWalletProvider().walletAddress;
    var toastError = useToast().toastError;
    var ref = useState(false), isLoading = ref[0], setLoading = ref[1];
    var ownerAddress = options.ownerAddress || walletAddress;
    var refreshBalance = useCallback(function() {
        if (ownerAddress && token && !skipUpdates) {
            return dispatch(fetchBalance({
                ownerAddress: ownerAddress,
                token: token
            }));
        }
        return Promise.resolve();
    }, [
        dispatch,
        fetchBalance,
        ownerAddress,
        token,
        skipUpdates
    ]);
    useInterval(function() {
        refreshBalance();
    }, usePolling ? 15000 : null);
    useEffect(function() {
        setLoading(true);
        refreshBalance().then(function(res) {
            var ref;
            setLoading(false);
            var errorMsg = (res === null || res === void 0 ? void 0 : (ref = res.error) === null || ref === void 0 ? void 0 : ref.message) || "";
            if (errorMsg) {
                toastError({
                    title: "Fail to get balance for ".concat(token.symbol)
                });
            }
        });
    }, [
        walletAddress,
        token
    ].concat(_to_consumable_array(deps)));
    var ref1;
    var tokenBalance = (ref1 = balances["".concat(ownerAddress, "_").concat(token.address)]) !== null && ref1 !== void 0 ? ref1 : {
        mint: token.address,
        amount: ZERO,
        decimals: token.decimals,
        uiAmount: ZERO
    };
    var hasBalance = !!balances["".concat(ownerAddress, "_").concat(token.address)];
    return {
        isLoading: isLoading,
        hasBalance: hasBalance,
        tokenBalance: tokenBalance,
        refreshBalance: refreshBalance
    };
};
