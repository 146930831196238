var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BN, Program, utils, web3 } from '@project-serum/anchor';
import { uniqBy } from 'lodash';
import { confirmTransaction, createAssociatedTokenAccountInstrs, createTempWSOLAccountInstrs, findAssociatedTokenAccount, sendTransaction, WSOL_MINT_ADDRESS } from '../../anchor';
import { PRT_VAULTS_CONFIG } from '../../constants/vaults';
import { PRT_PROGRAM_ID } from '../config';
import { AnchorParrotIDL } from '../config/parrot';
import { createBorrowInstrs, createInitializeDebtTypeInstrs, createInitializeVaultInstrs, createInitializeVaultTypeInstrs, createLiquidateInstrs, createRepayInstrs, createStakeInstrs, createUnstakeInstrs } from '../instructions';
export class ParrotProgram {
    constructor(provider) {
        this.provider = provider;
        this.program = new Program(AnchorParrotIDL, this.programId, provider);
        this.debtTypes = this.parrotConfig
            .filter(item => item['disabled'] !== true)
            .map(item => new web3.PublicKey(item.debtType.address));
        this.debtOriginators = this.parrotConfig
            .filter(item => item['disabled'] !== true)
            .map(item => new web3.PublicKey(item.debtType.debtOriginator.address));
        this.vaultTypes = this.parrotConfig
            .filter(item => item['disabled'] !== true)
            .map(item => new web3.PublicKey(item.address));
        this.priceOracles = uniqBy(this.parrotConfig
            .filter(item => item['disabled'] !== true)
            .map(item => new web3.PublicKey(item.state.priceOracle)), i => i.toBase58());
    }
    /**
     * Parrot Lending program ID
     *
     * @readonly
     * @memberof ParrotProgram
     */
    get programId() {
        var _a, _b;
        return new web3.PublicKey((_b = (_a = process.env.PROGRAM_PARROT) !== null && _a !== void 0 ? _a : process.env.NEXT_PUBLIC_PROGRAM_PARROT) !== null && _b !== void 0 ? _b : PRT_PROGRAM_ID);
    }
    /**
     * Parrot Lending program Vaults config
     *
     * @readonly
     * @memberof ParrotProgram
     */
    get parrotConfig() {
        return PRT_VAULTS_CONFIG.filter(vaultType => vaultType.deployment === this.deployment);
    }
    get ctx() {
        return { provider: this.provider, program: this.program };
    }
    get deployment() {
        var _a, _b;
        return ((_b = (_a = process.env.NEXT_PUBLIC_DEPLOYMENT) !== null && _a !== void 0 ? _a : process.env.DEPLOYMENT) !== null && _b !== void 0 ? _b : 'staging');
    }
    confirmTransaction(transaction, txId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield confirmTransaction(this.provider, txId, transaction, 'confirmed');
        });
    }
    getDebtOriginator(vaultType) {
        const cfg = this.parrotConfig.find(i => i.address === vaultType.toBase58());
        if (!cfg) {
            throw new Error(`Could not find debt token authority for vault type ${vaultType}`);
        }
        return {
            debtOriginator: new web3.PublicKey(cfg.debtType.debtOriginator.address),
            debtOriginatorAuthority: new web3.PublicKey(cfg.debtType.pda.address)
        };
    }
    getCollateralTokenHolder(vaultType) {
        const cfg = this.parrotConfig.find(i => i.address === vaultType.toBase58());
        if (!cfg) {
            throw new Error(`Could not find collateral token authority for vault type ${vaultType}`);
        }
        return {
            collateralTokenHolder: new web3.PublicKey(cfg.collateralTokenHolder),
            collateralTokenHolderAuthority: new web3.PublicKey(cfg.collateralTokenHolder.address)
        };
    }
    getDebtType(publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.program.account.debtType.fetch(publicKey);
        });
    }
    getVaultType(publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.program.account.vaultType.fetch(publicKey);
        });
    }
    getVault(publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.program.account.vault.fetch(publicKey);
        });
    }
    decodeVaultAccount(buffer) {
        return this.program.account.vault.coder.accounts.decode('vault', buffer);
    }
    decodeVaultTypeAccount(buffer) {
        return this.program.account.vaultType.coder.accounts.decode('vaultType', buffer);
    }
    getVaultsByOwner(owner) {
        return __awaiter(this, void 0, void 0, function* () {
            // only get known vaultTypes
            const vaultAddresses = [];
            for (const vaultType of this.vaultTypes) {
                vaultAddresses.push(yield this.assocatedVaultAddress(owner, vaultType));
            }
            const vaultAccounts = yield utils.rpc.getMultipleAccounts(this.provider.connection, vaultAddresses);
            return vaultAccounts
                .filter(account => !!account)
                .map(account => {
                return {
                    publicKey: account.publicKey,
                    account: this.decodeVaultAccount(account.account.data)
                };
            });
        });
    }
    assocatedVaultAddress(owner, vaultType) {
        return __awaiter(this, void 0, void 0, function* () {
            return utils.publicKey.associated(this.programId, owner, vaultType);
        });
    }
    initDebtType(props, signers = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const { instructions: main, cleanupInstructions, signers: initDebtTypeSigners } = yield createInitializeDebtTypeInstrs(this.ctx, Object.assign(Object.assign({}, props), { owner: this.provider.wallet.publicKey }));
            const { txId, transaction } = yield sendTransaction(this.provider, main, cleanupInstructions, [...signers, ...initDebtTypeSigners]);
            return {
                txId,
                transaction
            };
        });
    }
    initVaultType(args, props, signers = []) {
        return __awaiter(this, void 0, void 0, function* () {
            const { instructions: main, cleanupInstructions, signers: initVaultTypeSigners } = yield createInitializeVaultTypeInstrs(this.ctx, {
                args,
                accounts: props
            });
            const { txId, transaction } = yield sendTransaction(this.provider, main, cleanupInstructions, [...signers, ...initVaultTypeSigners]);
            return {
                txId,
                transaction
            };
        });
    }
    mint({ vaultType, vault, debtAmount, collateralAmount, debtToken, collateralToken, collateralTokenAccount }) {
        return __awaiter(this, void 0, void 0, function* () {
            const signers = [];
            const instructions = [];
            const cleanupInstructions = [];
            let vaultAccountExist = true;
            let debtTokenAccountExist = true;
            const bnDebtAmount = new BN(debtAmount);
            const bnCollateralAmount = new BN(collateralAmount);
            if (bnCollateralAmount.isZero() && bnDebtAmount.isZero()) {
                throw new Error('Ether a debt or collateral amount is required');
            }
            let collateralTokenAccountPk = collateralTokenAccount;
            // handle SOL token account
            if (collateralToken.equals(WSOL_MINT_ADDRESS)) {
                const { instructions: createTempWSOLAccountInstructions, cleanupInstructions: createTempWSOLAccountCleanupInstructions, signers: createTempWSOLAccountSigners, extra: { tokenAccount: wsolTokenAccount } } = yield createTempWSOLAccountInstrs(this.provider, {
                    lamports: Number.parseInt(collateralAmount)
                });
                collateralTokenAccountPk = wsolTokenAccount.publicKey;
                instructions.push(...createTempWSOLAccountInstructions);
                cleanupInstructions.push(...createTempWSOLAccountCleanupInstructions);
                signers.push(...createTempWSOLAccountSigners);
            }
            if (!collateralTokenAccountPk) {
                throw new Error('Trying to deposit funds but user do not have a collateral token address');
            }
            let debtTokenAccountPk = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, debtToken);
            if (!debtTokenAccountPk) {
                debtTokenAccountExist = false;
                const { instructions: createTokenAccountInstructions, signers: createTokenAccountSigners, extra: { tokenAccount } } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: debtToken,
                    owner: this.provider.wallet.publicKey
                });
                debtTokenAccountPk = tokenAccount;
                instructions.push(...createTokenAccountInstructions);
                signers.push(...createTokenAccountSigners);
            }
            if (!debtTokenAccountPk) {
                throw new Error('Trying to receive funds but user do not have a debt token address');
            }
            let vaultPk;
            if (!vault) {
                vaultAccountExist = false;
                // Create a new vault if I don't have vaultAddress
                const vault = yield this.assocatedVaultAddress(this.provider.wallet.publicKey, vaultType);
                const { instructions: createVaultInstructions } = yield createInitializeVaultInstrs(this.ctx, {
                    vault,
                    vaultType,
                    owner: this.provider.wallet.publicKey
                });
                instructions.push(...createVaultInstructions);
                vaultPk = vault;
            }
            else {
                vaultPk = vault;
            }
            // Check if need to deposit collateral
            if (!bnCollateralAmount.isZero()) {
                const { instructions: stakeInstructions } = yield createStakeInstrs(this.ctx, {
                    vaultType,
                    vault: vaultPk,
                    collateralFrom: collateralTokenAccountPk,
                    collateralFromAuthority: this.provider.wallet.publicKey,
                    collateralAmount: bnCollateralAmount
                });
                instructions.push(...stakeInstructions);
            }
            // Check if need to mint debt
            if (!bnDebtAmount.isZero()) {
                const { instructions: borrowInstructions } = yield createBorrowInstrs(this.ctx, {
                    vaultType,
                    vault: vaultPk,
                    vaultOwner: this.provider.wallet.publicKey,
                    receiver: debtTokenAccountPk,
                    debtAmount: bnDebtAmount
                });
                instructions.push(...borrowInstructions);
            }
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers);
            return {
                txId,
                transaction,
                vaultAddress: vaultPk.toBase58(),
                vaultAccountExist,
                debtTokenAccountExist
            };
        });
    }
    repay({ vaultType, vault, repayAmount, collateralAmount, debtTokenAccount, collateralToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const bnDebtAmount = new BN(repayAmount);
            const bnCollateralAmount = new BN(collateralAmount);
            let collateralTokenAccountExist = true;
            if (!vault) {
                throw new Error('Trying to repay but user do not have a vault');
            }
            if (bnCollateralAmount.isZero() && bnDebtAmount.isZero()) {
                throw new Error('Ether a debt or collateral amount is required');
            }
            const instructions = [];
            const signers = [];
            const cleanupInstructions = [];
            let collateralTokenAccountPk = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, collateralToken);
            // handle SOL token account
            if (collateralToken.equals(WSOL_MINT_ADDRESS)) {
                const { instructions: createTempWSOLAccountInstructions, cleanupInstructions: createTempWSOLAccountCleanupInstructions, signers: createTempWSOLAccountSigners, extra: { tokenAccount: collateralTokenAccount } } = yield createTempWSOLAccountInstrs(this.provider, {
                    lamports: 0
                });
                collateralTokenAccountPk = collateralTokenAccount.publicKey;
                instructions.push(...createTempWSOLAccountInstructions);
                cleanupInstructions.push(...createTempWSOLAccountCleanupInstructions);
                signers.push(...createTempWSOLAccountSigners);
            }
            else if (!collateralTokenAccountPk) {
                collateralTokenAccountExist = false;
                const { instructions: createTokenAccountInstructions, signers: createTokenAccountSigners, extra: { tokenAccount } } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: collateralToken,
                    owner: this.provider.wallet.publicKey
                });
                collateralTokenAccountPk = tokenAccount;
                instructions.push(...createTokenAccountInstructions);
                signers.push(...createTokenAccountSigners);
            }
            if (!collateralTokenAccountPk) {
                throw new Error('Trying to withdraw funds but user do not have a collateral token address');
            }
            if (!bnDebtAmount.isZero()) {
                if (!debtTokenAccount) {
                    throw new Error('Trying to repay debt but user do not have a debt token address');
                }
                const { instructions: repayInstructions } = yield createRepayInstrs(this.ctx, {
                    vaultType,
                    vault,
                    debtFrom: debtTokenAccount,
                    debtFromAuthority: this.provider.wallet.publicKey,
                    repayAmount: bnDebtAmount
                });
                instructions.push(...repayInstructions);
            }
            if (!bnCollateralAmount.isZero()) {
                const { instructions: unstakeInstrunctions } = yield createUnstakeInstrs(this.ctx, {
                    vaultType,
                    vault,
                    vaultOwner: this.provider.wallet.publicKey,
                    receiver: collateralTokenAccountPk,
                    unstakeAmount: bnCollateralAmount
                });
                instructions.push(...unstakeInstrunctions);
            }
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers);
            return { txId, transaction, collateralTokenAccountExist };
        });
    }
    liquidate({ vault, debtFrom, debtFromAuthority, liquidateAmount }) {
        return __awaiter(this, void 0, void 0, function* () {
            const vaultData = yield this.getVault(vault);
            const vaultType = yield this.getVaultType(vaultData.vaultType);
            const instructions = [];
            const signers = [];
            const cleanupInstructions = [];
            const bnLiquidateAmount = new BN(liquidateAmount);
            let liquidatedCollateralReceiver = yield findAssociatedTokenAccount(this.provider, this.provider.wallet.publicKey, vaultType.collateralToken);
            if (!liquidatedCollateralReceiver) {
                const { instructions: createTokenAccountInstructions, signers: createTokenAccountSigners, extra: { tokenAccount } } = yield createAssociatedTokenAccountInstrs(this.provider, {
                    tokenMint: vaultType.collateralToken,
                    owner: this.provider.wallet.publicKey
                });
                liquidatedCollateralReceiver = tokenAccount;
                instructions.push(...createTokenAccountInstructions);
                signers.push(...createTokenAccountSigners);
            }
            const { instructions: liquidateInstructions } = yield createLiquidateInstrs(this.ctx, {
                vaultType: vaultData.vaultType,
                vault,
                debtFrom,
                debtFromAuthority,
                liquidatedCollateralReceiver,
                liquidateAmount: bnLiquidateAmount
            });
            instructions.push(...liquidateInstructions);
            const { txId, transaction } = yield sendTransaction(this.provider, instructions, cleanupInstructions, signers);
            return { txId, transaction };
        });
    }
}
