import { web3 } from '@project-serum/anchor';
import vaults from './data/prt/vaultTypes/byAddress.go.json';
export const PRT_VAULTS_CONFIG = Object.values(vaults);
export const getVaultTypeConfig = (vaultType) => {
    const config = PRT_VAULTS_CONFIG.find(i => i.address === vaultType.toBase58());
    if (!config) {
        throw new Error(`Could not find vault type: ${vaultType.toBase58()}`);
    }
    return Object.assign(Object.assign({}, config), { address: new web3.PublicKey(config.address), debtTypeAddress: new web3.PublicKey(config.debtType.address), debtTokenAddress: new web3.PublicKey(config.debtType.state.debtToken), debtOriginator: new web3.PublicKey(config.debtType.debtOriginator.address), debtOriginatorAuthority: new web3.PublicKey(config.debtType.pda.address), interestsHolder: new web3.PublicKey(config.debtType.interestsHolder.address), collateralTokenAddress: new web3.PublicKey(config.state.collateralToken), collateralTokenHolder: new web3.PublicKey(config.collateralTokenHolder.address), collateralTokenHolderAuthority: new web3.PublicKey(config.pda.address), priceOracle: new web3.PublicKey(config.state.priceOracle) });
};
