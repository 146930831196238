var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AccountLayout } from '@solana/spl-token';
export function findTokenAccount(provider, owner, tokenMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const { value } = yield provider.connection.getTokenAccountsByOwner(owner, { mint: tokenMint }, 'processed');
        const tokenAccounts = value.map(i => (Object.assign(Object.assign({}, i), { data: AccountLayout.decode(i.account.data) })));
        tokenAccounts.sort((a, b) => b.data.amount - a.data.amount);
        return tokenAccounts.length ? tokenAccounts[0].pubkey : undefined;
    });
}
