import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    transactions: []
};
export var transactionSlice = createSlice({
    name: "transaction",
    initialState: initialState,
    reducers: {
        fetchTransactions: function(state, action) {
            var transactions = [];
            try {
                transactions = JSON.parse(localStorage.getItem("transactions") || "[]");
            } catch (e) {
                transactions = [];
            }
            transactions = Array.isArray(transactions) ? transactions : [];
            state.transactions = transactions.filter(function(i) {
                return i.owner === action.payload;
            });
        },
        removeTransaction: function(state, action) {
            state.transactions = state.transactions.filter(function(i) {
                return i.txId !== action.payload;
            });
            localStorage.setItem("transactions", JSON.stringify(state.transactions));
        },
        addTransaction: function(state, action) {
            // TODO: for now we store only the last transaction
            state.transactions = [
                action.payload
            ];
            localStorage.setItem("transactions", JSON.stringify(state.transactions));
        }
    }
});
// Actions
var _actions = transactionSlice.actions, addTransaction = _actions.addTransaction, fetchTransactions = _actions.fetchTransactions, removeTransaction = _actions.removeTransaction, transactionReducer = transactionSlice.reducer;
export { addTransaction, fetchTransactions, removeTransaction, transactionReducer };
