import { useEffect, useRef } from "react";
function useInterval(callback, delay) {
    var savedCallback = useRef(callback);
    // Remember the latest callback if it changes.
    useEffect(function() {
        savedCallback.current = callback;
    }, [
        callback
    ]);
    // Set up the interval.
    useEffect(function() {
        // Don't schedule if no delay is specified.
        if (delay === null) {
            return;
        }
        var id = setInterval(function() {
            // TODO: consider use document.hasFocus() or window.onBlur/onFocus
            savedCallback.current();
        }, delay);
        return function() {
            return clearInterval(id);
        };
    }, [
        delay
    ]);
}
export default useInterval;
