import BigNumber from 'bignumber.js';
import { INFINITY, ZERO } from '../../anchor';
export const getCollateralRatio = (debtAmount, collateralAmount, debtTokenDecimals, collateralTokenDecimals, collateralTokenPriceFloat, percentMultiplier = 1) => {
    if (!collateralTokenPriceFloat) {
        return ZERO.toString();
    }
    const debtAmountBN = new BigNumber(debtAmount);
    const collateralAmountBN = new BigNumber(collateralAmount);
    const collateralTokenPriceFloatBN = new BigNumber(collateralTokenPriceFloat);
    if (debtAmountBN.isZero()) {
        return INFINITY.toString();
    }
    else if (collateralAmountBN.isZero()) {
        return ZERO.toString();
    }
    const collateralAmountFixed = collateralAmountBN.dividedBy(Math.pow(10, collateralTokenDecimals));
    const debtAmountFixed = debtAmountBN.dividedBy(Math.pow(10, debtTokenDecimals));
    const collateralRatio = collateralAmountFixed
        .multipliedBy(collateralTokenPriceFloatBN)
        .dividedBy(debtAmountFixed);
    return BigNumber.max(collateralRatio, ZERO)
        .multipliedBy(percentMultiplier)
        .toString();
};
