import { CHANNEL, VERSION } from "config/constants";
import { useEffect } from "react";
var useSystemInfo = function() {
    useEffect(function() {
        if ("object" !== undefined) {
            window.PARROT_VERSION = VERSION;
            window.PARROT_CHANNEL = CHANNEL;
        }
    }, [
        VERSION,
        CHANNEL
    ]);
    return;
};
export { useSystemInfo };
